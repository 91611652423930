import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '@components/notification/notification.component';
import { take } from 'rxjs/operators';
import { NotificationConfig } from '@interfaces/notification-config.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private DEFAULT_CONFIG = {
    duration: 3000,
    panelClass: 'snackbar',
  };

  constructor(private snackBar: MatSnackBar) {}

  onMessage(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config });
  }

  onSuccess(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config, panelClass: 'success' });
  }

  onWarning(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config, panelClass: 'warning' });
  }

  onError(config: NotificationConfig) {
    this._openSnackbar({ ...this.DEFAULT_CONFIG, ...config, panelClass: 'error' });
  }

  private _openSnackbar(config: NotificationConfig) {
    const message: string = config.message
      ? config.message.toString()
      : 'NOTIFICATIONS.API.UNKNOWN_ERROR';
    const snackbarRef = this.snackBar.openFromComponent(NotificationComponent, {
      ...config,
      data: {
        message,
        messageParams: config.messageParams,
        icon: config.icon,
      },
    });

    if (config.onAction) {
      snackbarRef
        .onAction()
        .pipe(take(1))
        .subscribe((res) => config.onAction(res));
    }
  }
}
