import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssa-password-strength-validator',
  templateUrl: './password-strength-validator.component.html',
})
export class PasswordStrengthValidatorComponent {
  characterCount: boolean;
  upperCase: boolean;
  lowerCase: boolean;
  numbers: boolean;

  @Input()
  set password(value: string) {
    if (value) {
      this.characterCount = value.length >= 8;
      this.upperCase = /^(?=.*[A-Z]).*$/.test(value);
      this.lowerCase = /^(?=.*[a-z]).*$/.test(value);
      this.numbers = /[0-9]/.test(value);
    } else {
      this.characterCount = false;
      this.upperCase = false;
      this.lowerCase = false;
      this.numbers = false;
    }
  }
}
