export class QuestionQueue<T> {
  private readonly _queue: T[] = [];
  private _currentIndex: number;

  constructor(list: T[]) {
    if (list == null) {
      return;
    }

    this._queue = list;
    this.reset();
  }

  push(val: T) {
    this._queue.push(val);
  }

  pop(): T | undefined {
    return this._queue[++this._currentIndex];
  }

  reversePop(): T | undefined {
    return this._queue[--this._currentIndex];
  }

  hasPrevious(): boolean {
    return this._currentIndex > 0;
  }

  hasNext(): boolean {
    return this._currentIndex + 1 < this._queue?.length;
  }

  reset(): void {
    this._currentIndex = -1;
  }

  get currentIndex(): number {
    return this._currentIndex + 1;
  }

  get lastIndex(): number {
    return this._queue?.length;
  }
}
