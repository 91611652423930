import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssessmentQuestion } from '../../../models/assessment-question.model';

@Component({
  selector: 'ssa-guidelines-popover',
  templateUrl: './guidelines-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuidelinesPopoverComponent {
  @Input() question: AssessmentQuestion;
}
