import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Events {
  private _channels: { [topic: string]: EventEmitter<any> };

  constructor() {
    this._channels = {};
  }

  subscribe(topic: string, handler: any): Subscription {
    if (!this._channels[topic]) {
      this._channels[topic] = new EventEmitter();
    }

    return this._channels[topic].subscribe(handler);
  }

  publish(topic: string, value?: any) {
    const t = this._channels[topic];
    if (!t) {
      return null;
    }

    t.emit(value);
  }
}
