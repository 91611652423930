import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QUESTION_TYPE, REGEX } from '@shared/constants';
import { Assessment } from '../../models/assessment.model';
import { AssessmentQuestion } from '../../models/assessment-question.model';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { AssessmentCategory } from '@shared/models/assessment-category.model';

interface GroupQuestionsValidationResult {
  name: string;
  totalPercentage: number;
}
@Injectable({
  providedIn: 'root',
})
export class QuestionValidator {
  constructor(public dialog: MatDialog) {}
  private static validateRequired(question: AssessmentQuestion): string {
    if (question.required && !question.answer.isAnswered) {
      return 'COMMON.FORM_ERRORS.REQUIRED';
    }
  }

  private static validateDate(question: AssessmentQuestion): string {
    if (isNaN(Date.parse(question.answer.answer))) {
      return 'COMMON.FORM_ERRORS.INVALID_DATE';
    }
  }

  private static validateNumber(question: AssessmentQuestion): string {
    if (isNaN(parseInt(question.answer.answer, 10))) {
      return 'COMMON.FORM_ERRORS.INVALID_NUMBER';
    }
  }

  private static validatePercentage(question: AssessmentQuestion): string {
    const answer = parseInt(question.answer.answer, 10);
    if (isNaN(answer)) {
      return 'COMMON.FORM_ERRORS.INVALID_NUMBER';
    }
    if (answer > 100 || answer < 0) {
      return 'COMMON.FORM_ERRORS.INVALID_PERCENTAGE';
    }
  }

  private static validateEmail(question: AssessmentQuestion): string {
    if (!REGEX.EMAIL.test(question.answer.answer)) {
      return 'COMMON.FORM_ERRORS.INVALID_EMAIL';
    }
  }

  private static validatePhone(question: AssessmentQuestion): string {
    if (!REGEX.PHONE.test(question.answer.answer)) {
      return 'COMMON.FORM_ERRORS.INVALID_PHONE';
    }
  }

  private static validateSpecialQuestions(question: AssessmentQuestion) {
    switch (question.questionType) {
      case QUESTION_TYPE.DATE:
        question.error = QuestionValidator.validateDate(question);

        break;
      case QUESTION_TYPE.NUMBER:
        question.error = QuestionValidator.validateNumber(question);

        break;
      case QUESTION_TYPE.PERCENTAGE:
        question.error = QuestionValidator.validatePercentage(question);

        break;
      case QUESTION_TYPE.EMAIL:
        question.error = QuestionValidator.validateEmail(question);

        break;
      case QUESTION_TYPE.PHONE:
        question.error = QuestionValidator.validatePhone(question);

        break;
    }
  }

  public isAssessmentValid(assessment: Assessment): boolean {
    assessment.questions
      .filter((q) => q.questionType !== QUESTION_TYPE.PARENT)
      .forEach((question) => this.setQuestionError(question));

    // validate group questions
    const groupQuestions = assessment.questions.filter((q) => q.questionGroup);
    const groupValidationResult = this.validateGroupQuestions(groupQuestions);

    // show dialog for each incorrect group question set
    groupValidationResult.forEach((validationResult) => {
      this.showDialogForQuestionGroup(
        `${validationResult.name} is not correct`,
        `The questions under '${validationResult.name}' should make a total of 100%, but currently it is ${validationResult.totalPercentage}%`,
      );
    });

    return !this.hasErrors(assessment);
  }

  private validateGroupQuestions(
    groupQuestions: AssessmentQuestion[],
  ): GroupQuestionsValidationResult[] {
    const result: GroupQuestionsValidationResult[] = [];

    const groupQuestionIdsHandled: number[] = [];
    let totalPercentage = 0;
    groupQuestions.forEach((gq) => {
      if (!groupQuestionIdsHandled.includes(gq.questionGroup.id)) {
        groupQuestionIdsHandled.push(gq.questionGroup.id);

        const currentGroupQuestions = groupQuestions.filter(
          (currentQuestion) => gq.questionGroup.id === currentQuestion.questionGroup.id,
        );

        currentGroupQuestions
          .filter((q) => q.questionType === 'percentage')
          .forEach((q) => (totalPercentage += q.answer.answer as number));

        if (!isNaN(totalPercentage) && totalPercentage !== 100) {
          currentGroupQuestions.forEach((question) => {
            question.error = 'Would reconsider to make a total of 100%';
          });

          result.push({
            name: gq.questionGroup.name,
            totalPercentage: totalPercentage,
          });
        }
        totalPercentage = 0;
      }
    });

    return result;
  }

  private showDialogForQuestionGroup(title: string, message: string): void {
    this.dialog.open(DialogComponent, {
      data: {
        title,
        message,
      },
      hasBackdrop: true,
      closeOnNavigation: true,
      autoFocus: true,
      role: 'alertdialog',
    });
  }

  public setQuestionError(question: AssessmentQuestion) {
    if (question.questionType === QUESTION_TYPE.PARENT) return;

    if (question.answer.answer == null) {
      question.error = undefined;
    }

    question.error = QuestionValidator.validateRequired(question);
    if (question.error != null) {
      return question.error;
    } else {
      QuestionValidator.validateSpecialQuestions(question);
    }
  }

  public hasErrors(assessment: Assessment): boolean {
    if (!assessment) {
      return true;
    }

    return assessment.questions
      .filter((q) => q.questionType !== QUESTION_TYPE.PARENT)
      .some((q) => q.error != null || !q.answer || !q.answer.isAnswered);
  }

  public validateAllQuestionsAnswered(assessment: Assessment): boolean {
    return assessment.questions
      .filter((q) => q.questionType !== QUESTION_TYPE.PARENT)
      .every((q) => q.answer.isAnswered);
  }

  public validateAllQuestionsAnsweredInCategory(
    assessment: Assessment,
    category: AssessmentCategory,
  ): boolean {
    const questions = assessment.questions.filter(
      (q) => q.category.id === category.id && q.questionType !== QUESTION_TYPE.PARENT,
    );

    return questions.every((q) => q.answer.isAnswered);
  }
}
