import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(private notification: NotificationService) {}

  detectBrowser() {
    const info = this.getBrowserInfo();
    if (
      (info.name === 'Chrome' && info.version < 45) ||
      (info.name === 'Firefox' && info.version < 44) ||
      (info.name === 'Safari' && info.version < 11) ||
      (info.name === 'Opera' && info.version < 32)
    ) {
      this.notification.onError({
        message: 'Your browser is outdated. Please update for a better app experience',
        duration: null,
      });
    }
  }

  getBrowserInfo() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) {
      M.splice(1, 1, tem[1]);
    }

    return {
      name: M[0],
      version: parseFloat(M[1]),
    };
  }
}
