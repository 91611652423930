export class QIPActivity {
  id: number;
  description: string;
  note: string;
  activityDate: Date;
  checked: boolean;
  assessmentId: number;
  qipActivityTemplateId: number;
  qipQuestionTemplateId: number;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.assessmentId = data.assessmentId;
      this.qipActivityTemplateId = data.qipActivityTemplateId;
      this.qipQuestionTemplateId = data.qipQuestionTemplateId;
      this.activityDate = data.activityDate ? new Date(data.activityDate) : null;
      this.checked = data.checked;
      this.description = data.description;
      this.note = data.note;
    }
  }
}
