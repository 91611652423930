import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  registerIcons() {
    this.iconRegistry.addSvgIcon(
      'assessment',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/assessment.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'password',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/password.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'email',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/mail.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'menu',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'back_arrow',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/back-arrow.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'forward_arrow',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/forward-arrow.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'login_arrow',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/login-arrow.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'logout_arrow',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/logout-arrow.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'download',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/download.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'side-menu-faq',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-faq.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'side-menu-home',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-home.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'side-menu-mail',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-mail.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'side-menu-poster',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-print-poster.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'side-menu-website',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-website.svg'),
    );

    this.iconRegistry.addSvgIcon(
      'camera',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/camera.svg'),
    );
  }
}
