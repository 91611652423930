export class WhitelabelSettings {
  primaryColor: string;
  logo: string;
  bannerImage: string;
  website: string;
  canRegister: boolean;
  canChangeLanguage: boolean;

  constructor(jsonData: any) {
    if (jsonData == null) return;

    this.primaryColor = jsonData.primaryColor;
    this.logo = jsonData.logo;
    this.bannerImage = jsonData.bannerImage;
    this.website = jsonData.website;
    this.canRegister = jsonData.canRegister;
    this.canChangeLanguage = jsonData.canChangeLanguage;
  }
}
