import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { getQueryStringParams } from '@shared/utils';
import { CacheKeys } from '@shared/enums/cache-keys';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CacheHelper } from '@shared/providers/api/cache-helper';
import { SettingsService } from '@shared/providers/settings/settings.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LanguageLoader } from '@shared/providers/language-loader/language-loader';
import { TranslationsApiService } from '@shared/providers/api/translations-api.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

export function LanguageLoaderFactory(translationsService: TranslationsApiService) {
  return new LanguageLoader(translationsService);
}

export function InitializeTranslations(translateService: TranslateService) {
  return () =>
    new Promise<void>((resolve) => {
      const selectedLanguage = CacheHelper.fetchFromCache(
        CacheKeys.CURRENT_LANGUAGE,
        environment.language,
      );
      const urlLanguage = getQueryStringParams(window.location.search).lang;
      const defaultLanguage = typeof urlLanguage === 'string' ? urlLanguage : selectedLanguage;

      CacheHelper.writeToCache(CacheKeys.CURRENT_LANGUAGE, defaultLanguage);
      translateService.setDefaultLang(defaultLanguage);

      resolve();
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.analytics),
    NgxGoogleAnalyticsRouterModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoaderFactory,
        deps: [TranslationsApiService],
      },
    }),
    AppRoutingModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeTranslations,
      deps: [TranslateService, SettingsService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
