export class FacilitySettings {
  id: number;
  headerImage: string;
  logoImage: string;
  createdOn: Date;
  updatedOn: Date;
  active: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.headerImage = data.headerImage;
      this.logoImage = data.logoImage;
      this.createdOn = data.createdOn;
      this.updatedOn = data.updatedOn;
      this.active = data.active;
    }
  }
}
