<mat-sidenav-container class="side-menu" [class.authenticated]="isAuthenticated">

  <mat-sidenav mode="over" position="end" role="navigation" *ngIf="isAuthenticated" [opened]="sideNav" (closed)="close()">
    <mat-nav-list class="side-menu--list">
      <a
        mat-list-item
        *ngFor="let item of menuItems"
        [routerLink]="item.link"
        (click)="close()"
        routerLinkActive="active"
        class="side-menu--item"
        gaEvent
        [gaAction]="item.id"
        gaCategory="sidemenu"
      >
        <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
        <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
        <span class="menu-label">{{item.label | translate}}</span>
      </a>
    </mat-nav-list>

    <div class="posters-container">
      <ssa-side-menu-posters></ssa-side-menu-posters>
      <ssa-side-menu-additional-resources></ssa-side-menu-additional-resources>
    </div>

    <button mat-raised-button class="icon-btn logout-btn" (click)="logout()">
      <mat-icon svgIcon="logout_arrow"></mat-icon>
      <span class="icon-btn-label">{{"BUTTONS.LOGOUT" | translate}}</span>
    </button>
  </mat-sidenav>

  blabla
  <h2 class="list--header">{{'MENU.POSTERS' | translate}}</h2>


  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
