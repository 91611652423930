import { SCORE_TYPE } from '../constants';

export class AssessmentAnswer {
  id: number;
  answerTemplateId: number;
  questionTemplateId: number;
  assessmentId: number;
  answer: any;
  note: string;
  score: string;

  constructor(data?: any, restore?: boolean) {
    if (data) {
      this.id = data.id;
      this.answerTemplateId = data.answerTemplateId;
      this.questionTemplateId = data.questionTemplateId;
      this.assessmentId = data.assessmentId;
      this.note = data.note;

      if (restore) {
        this.score = data.score;
        this.answer = data.answer;
      } else {
        this.answer = data.openAnswer;
        this.score = data.answerTemplate?.score;
      }
    }
  }

  get isAnswered(): boolean {
    return this.answer != null || this.answerTemplateId != null;
  }

  get isAnsweredCorrectly(): boolean {
    return this.score === SCORE_TYPE.FC || this.score === SCORE_TYPE.NA;
  }
}
