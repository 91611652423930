import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'ssa-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent {
  message: string;
  messageParams: any;
  icon: string;
  action: string;
  panelClass: string;

  @HostBinding('class') class = 'notification-container';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) data: any,
    private _snackbarRef: MatSnackBarRef<NotificationComponent>,
  ) {
    if (data) {
      this.message = data.message;
      this.messageParams = data.messageParams;
      this.icon = data.icon;
      this.action = data.action;
      this.panelClass = data.panelClass;
    }
  }

  onAction() {
    this._snackbarRef.dismiss();
  }
}
