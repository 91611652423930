<mat-toolbar class="app-header-inner" *ngIf="!isLoginPage && isAuthenticated$ | async" color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon class="navigation-icon" svgIcon="back_arrow"></mat-icon>
    </button>

    <span class="header-label" *ngIf="headerLabel">{{headerLabel | translate}}</span>

    <button mat-icon-button (click)="toggleSideNav()" class="menu-toggle-button" id="mainMenuBtn">
      <mat-icon>menu</mat-icon>
      <span class="menu-label">{{'BUTTONS.MENU' | translate}}</span>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
