import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '@interfaces/api-response.interface';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CacheHelper } from './cache-helper';
import { environment } from '@environments/environment';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  private static get apiUrl(): string {
    return environment.apiUrl;
  }

  private throwError(response: any) {
    let errorMessage = response.message;
    if (response.error) {
      errorMessage = response.error.error;
    }

    this.notificationService.onError({ message: errorMessage });
    return of();
  }

  /**
   * Handle Http operation that failed.
   * @param response HttpErrorResponse - the actual error message from the server
   * @param uri
   */
  private _serverError(response: any, uri: string): Observable<any> {
    if (response.status === 0 && uri != null) {
      return of(CacheHelper.fetchFromCache(uri));
    }

    if (!(response instanceof HttpErrorResponse) || response.status !== 401) {
      return this.throwError(response);
    }

    return this.throwError({ message: 'An unknown error has occurred' });
  }

  public get(url: string, options?: any): Observable<any> {
    const uri = BaseApiService.apiUrl + url;

    return Observable.create((observer) => {
      const cache = CacheHelper.fetchFromCache(uri);
      if (cache && !options?.ignoreCache) {
        observer.next(cache);
      }

      this.getFromAPI(uri, options).subscribe((response) => {
        observer.next(response);
      });
    });
  }

  private getFromAPI(uri: string, options: any): Observable<any> {
    this.checkConnection();

    return this.http.get<ApiResponse>(uri, options).pipe(
      map((res: any) => {
        if (options?.responseType !== 'blob') {
          res = res.result;
        }

        CacheHelper.writeToCache(uri, res);

        return res;
      }),
      catchError((err) => {
        if (options?.ignoreError) {
          return of();
        }

        return this._serverError(err, uri);
      }),
    );
  }

  public post(url: string, body: any): Observable<any> {
    return this.http.post<ApiResponse>(BaseApiService.apiUrl + url, body).pipe(
      map((res) => res.result),
      catchError((err) => this._serverError(err, null)),
    );
  }

  public put(url: string, body: any): Observable<any> {
    return this.http.put<ApiResponse>(BaseApiService.apiUrl + url, body).pipe(
      map((success) => success.result),
      catchError((error) => this._serverError(error, null)),
    );
  }

  private checkConnection() {
    if (!navigator.onLine) {
      this.throwError({
        message:
          'There is low or no internet connectivity, the app functionality is very limited at the moment.',
      });
    }
  }
}
