<ng-container>
  <div class="question-list-pager__container" *ngIf="assessment">
    <!--  NORMAL OVERVIEW  -->
    <div class="question-list-pager__question-page" *ngIf="!isOverview && currentQuestion">
      <div id="top" class="question-list-pager__question-page-category">
        <h2 class="question-list-pager__question-page-category--title">
          {{ currentQuestion.category.categoryName }}
        </h2>

        <button
          class="question-list-pager__question-page-category--overview-button"
          (click)="isOverview = true"
          mat-icon-button
        >
          <mat-icon>list</mat-icon>
        </button>

        <button
          class="question-list-pager__question-page-category--category-button"
          *ngIf="isAssessor"
          (click)="goToCategoryOverview()"
          mat-icon-button
        >
          <mat-icon>list</mat-icon>
        </button>

        <p *ngIf="!currentCategory" class="question-list-pager__question-page-category--count">
          {{ questionQueue.currentIndex }} / {{ questionQueue.lastIndex }}
        </p>

        <p *ngIf="currentCategory" class="question-list-pager__question-page-category--count">
          {{ getCurrentQuestionNumberForCategory(currentCategory) }}
          / {{ categories.length }}
        </p>
      </div>

      <!--   NORMAL USER PAGE  -->
      <div class="question-list-pager__question-page-content" *ngIf="!isAssessor">
        <ssa-question-viewer
          [(ngModel)]="currentQuestion.answer"
          (ngModelChange)="verifyAllAnswered()"
          [question]="currentQuestion"
          [assessmentType]="assessment.assessmentType"
          [horizontalAlignment]="false"
        >
        </ssa-question-viewer>

        <div *ngIf="currentQuestion.childQuestions">
          <ssa-question-viewer
            *ngFor="let cq of currentQuestion.childQuestions"
            [(ngModel)]="cq.answer"
            (ngModelChange)="submitSingleQuestion(cq.answer)"
            [question]="cq"
            [assessmentType]="assessment.assessmentType"
            [horizontalAlignment]="false"
          >
          </ssa-question-viewer>
        </div>

        <div class="question_list-pager__error-container">
          <div class="mat-error" *ngIf="!isCurrentQuestionAnswered()">
            * {{ 'PAGES.ERRORS.QUESTION_REQUIRED_MESSAGE' | translate }}
          </div>
        </div>

        <button
          [disabled]="!isCurrentQuestionAnswered()"
          (click)="submitSingleQuestion(currentQuestion.answer)"
          mat-flat-button
          color="primary"
          class="icon-btn form-button"
        >
          <span class="icon-btn-label">{{ 'BUTTONS.SUBMIT_ANSWER' | translate }}</span>
        </button>

        <!-- Generate answers for testing purpose -->
        <button
          mat-raised-button
          color="accent"
          class="full-width mt-3 mb-3"
          (click)="answerAllRandomly()"
          *ngIf="showTestButton"
        >
          Test button: Answer all randomly
        </button>
      </div>

      <!--   ASSESSOR PAGE   -->
      <div class="question-list-pager__question-page-content" *ngIf="isAssessor">
        <div *ngFor="let question of getAllQuestionsForCategory(currentCategory)">
          <ssa-question-viewer
            [(ngModel)]="question.answer"
            (ngModelChange)="submitSingleQuestion(question.answer)"
            [question]="question"
            [assessmentType]="assessment.assessmentType"
            [horizontalAlignment]="false"
            [showNote]="isAssessor"
          >
          </ssa-question-viewer>

          <div *ngIf="question.childQuestions">
            <ssa-question-viewer
              *ngFor="let cq of question.childQuestions"
              [(ngModel)]="cq.answer"
              (ngModelChange)="submitSingleQuestion(cq.answer)"
              [question]="cq"
              [assessmentType]="assessment.assessmentType"
              [horizontalAlignment]="false"
              [showNote]="isAssessor"
            >
            </ssa-question-viewer>
          </div>
        </div>

        <div
          class="question_list-pager__error-container"
          *ngIf="!verifyAllAnsweredInCategory(currentCategory) || !showManyataScore"
        >
          <div class="mat-error" *ngIf="!verifyAllAnsweredInCategory(currentCategory)">
            * {{ 'PAGES.ERRORS.QUESTION_REQUIRED_MESSAGE' | translate }}
          </div>
        </div>

        <div
          class="question_list-pager__category-container"
          *ngIf="verifyAllAnsweredInCategory(currentCategory) && showManyataScore"
        >

          <p>
            {{ 'PAGES.ASSESSMENT.STANDARD_SCORE' | translate }}
            {{ getCategoryFullyScored(currentCategory) ? 1 : 0 }} ({{
              getAmountAnsweredCorrectlyForCategory(currentCategory)
            }}
            /
            {{ getAmountOfQuestionsForCategoryManyata(currentCategory) }})
          </p>
        </div>

        <button
          [disabled]="!verifyAllAnsweredInCategory(currentCategory)"
          (click)="submitCurrentCategory()"
          mat-flat-button
          color="primary"
          class="icon-btn form-button"
        >
          <span class="icon-btn-label">{{ 'BUTTONS.SUBMIT_ANSWER' | translate }}</span>
        </button>
      </div>
    </div>

    <!--  ASSESSOR OVERVIEW  -->
    <div
      *ngIf="!currentCategory && isAssessor && !isOverview"
      class="question-list-pager__category-container"
    >
      <div *ngIf="showTestButton">
        <button
          mat-raised-button
          color="accent"
          class="full-width mb-3"
          (click)="answerAllRandomly()"
        >
          Test button: Answer all randomly
        </button>

        <button
          mat-raised-button
          color="accent"
          class="full-width mb-3"
          (click)="answerAllCorrectly()"
        >
          Test button: Answer all Correctly
        </button>
      </div>

      <div
        *ngFor="let category of categories; index as index"
        class="question-list-pager__button full-width"
      >
        <button
          *ngIf="!verifyAllAnsweredInCategory(category)"
          mat-raised-button
          color="primary"
          class="full-width icon-btn question-list-pager__button mb-3"
          (click)="setCurrentCategory(category)"
        >
          <span class="icon-btn-label">{{ index + 1 }}. {{ category.categoryName }}</span>
        </button>

        <button
          *ngIf="verifyAllAnsweredInCategory(category)"
          mat-stroked-button
          color="primary"
          class="full-width icon-btn question-list-pager__button mb-3"
          (click)="setCurrentCategory(category)"
        >
          <span class="icon-btn-label">
            {{ index + 1 }}. {{ category.categoryName }}
            <mat-icon class="question-list-pager__success-icon">done</mat-icon>
          </span>
        </button>
      </div>

      <button
        [disabled]="!valid"
        mat-raised-button
        color="accent"
        class="full-width icon-btn question-list-pager__button mb-3"
        (click)="navigateToOverview(true)"
      >
        <span class="icon-btn-label">{{ 'PAGES.ASSESSMENT.OVERVIEW' | translate }}</span>
      </button>
    </div>

    <div *ngIf="isOverview" class="question-list-pager__question-page-overview">
      <h3 class="assessment-subtitle subtitle">
        {{ 'PAGES.' + assessment.assessmentType?.toUpperCase() + '.SUB_TITLE' | translate }}
      </h3>

      <mat-accordion
        togglePosition="before"
        class="question-list-pager__question-page-expansion-panel mb-3"
      >
        <mat-expansion-panel *ngFor="let category of categories">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ category.categoryName }}
            </mat-panel-title>

            <mat-panel-description class="question-list-pager__question-page-expansion-panel-description">
              {{getScoreDefinition(category)}}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div
            class="question-list-pager__question-list-item"
            *ngFor="let question of getQuestionsForCategory(category); index as questionIndex"
          >
            <ssa-question-viewer
              *ngIf="question.parentQuestionId == null"
              [(ngModel)]="question.answer"
              (ngModelChange)="verifyAllAnswered()"
              [question]="question"
              [assessmentType]="assessment.assessmentType"
              [questionIdx]="questionIndex"
              [horizontalAlignment]="false"
              [showNote]="isAssessor"
            >
            </ssa-question-viewer>

            <div *ngIf="question.childQuestions">
              <ssa-question-viewer
                *ngFor="let cq of question.childQuestions"
                [(ngModel)]="cq.answer"
                (ngModelChange)="verifyAllAnswered()"
                [question]="cq"
                [assessmentType]="assessment.assessmentType"
                [horizontalAlignment]="false"
                [showNote]="isAssessor"
              >
              </ssa-question-viewer>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="question-list-pager__submit-container" *ngIf="isOverview && !currentCategory">
      <div *ngIf="!valid" class="invalid-message mt-3">
        * {{ 'PAGES.ERRORS.ALL_REQUIRED_MESSAGE' | translate }}
      </div>

      <button
        [disabled]="!valid"
        (click)="submit()"
        mat-flat-button
        color="accent"
        class="icon-btn form-button mt-3 question-list-pager__button"
      >
        <span class="icon-btn-label">{{ 'BUTTONS.SUBMIT_ASSESSMENT' | translate }}</span>
      </button>
    </div>
  </div>

  <div *ngIf="!assessment">
    <p>Retrieving assessment details...</p>
  </div>
</ng-container>
