import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs/operators';
import { QIP } from '@models/qip.model';
import { Injectable } from '@angular/core';
import { PROCESS_STATUS } from '../../constants';
import { Category } from '@models/category.model';
import { BaseApiService } from './base-api.service';
import { HttpResponse } from '@angular/common/http';
import { Assessment } from '@models/assessment.model';
import { QIPActivity } from '@models/qip-activity.model';
import { AssessmentScore } from '@models/assessment-score.model';
import { AssessmentAnswer } from '@models/assessment-answer.model';

@Injectable({
  providedIn: 'root',
})
export class AssessmentApiService extends BaseApiService {
  getAssessmentById(id: number): Observable<Assessment> {
    return this.get(`/assessment/${id}`).pipe(map((json) => new Assessment(cloneDeep(json, true))));
  }

  getAssessmentQuestions(assessmentType: string): Observable<Assessment> {
    return this.get(`/assessment/questions?assessmentType=${assessmentType}`, {
      ignoreCache: true,
    }).pipe(map((json) => new Assessment(cloneDeep(json, true))));
  }

  getAssessmentCategories(): Observable<Category[]> {
    return this.get('/assessment/categories')
      .pipe(map((response) => response.map((category) => new Category(category))))
      .pipe(
        map((countries) => {
          return countries;
        }),
      );
  }

  getLatestAssessment(
    facilityId: number,
    processStatus: string,
    assessmentType: string,
    ignoreError = true,
    ignoreCache = false,
  ): Observable<Assessment> {
    let uri = `/assessment/latest`;

    uri = this.addQueryToUri('facilityId', facilityId, uri);
    uri = this.addQueryToUri('processStatus', processStatus, uri);
    uri = this.addQueryToUri('assessmentType', assessmentType, uri);

    return this.get(uri, { ignoreError, ignoreCache }).pipe(
      map((json) => new Assessment(cloneDeep(json, true))),
    );
  }

  addQueryToUri(key: string, value: any, uri: string): string {
    if (value == null || key == null) return uri;

    const divider = uri.includes('?') ? '&' : '?';

    return `${uri}${divider}${key}=${value}`;
  }

  getQIP(assessmentId: number): Observable<QIP> {
    return this.get(`/assessment/${assessmentId}/qip`);
  }

  getQIPForAssessment(assessmentId: number): Observable<QIP> {
    return this.get(`/assessment/${assessmentId}/qip`).pipe(
      map((json) => new QIP(cloneDeep(json))),
    );
  }

  createActivity(activity: QIPActivity) {
    this.post(`/assessment/${activity.assessmentId}/qip/activity`, {
      assessmentId: activity.assessmentId,
      qipActivityTemplateId: activity.qipActivityTemplateId,
      checked: activity.checked,
    }).subscribe();
  }

  answerAssessment(assessment: Assessment): Observable<any> {
    const answers = [];
    assessment.questions.forEach((q) => {
      answers.push({
        answerTemplateId: q.answer.answerTemplateId,
        questionTemplateId: q.answer.questionTemplateId,
        answer: q.answer.answer,
        note: q.answer.note,
      });
    });

    return this.put(`/assessment/${assessment.id}/answer`, answers).pipe(
      map((json) => new Assessment(cloneDeep(json, true))),
    );
  }

  answerAssessmentQuestion(assessmentId: number, answer: AssessmentAnswer): Observable<any> {
    return this.put(`/assessment/${assessmentId}/answer-question`, answer).pipe(
      map((json) => new Assessment(cloneDeep(json, true))),
    );
  }

  createAssessment(
    assessmentTemplateId: number,
    assessmentType: string,
    facilityId: number,
  ): Observable<Assessment> {
    return this.post(`/assessment/`, {
      assessmentTemplateId,
      assessmentType,
      facilityId,
      processStatus: PROCESS_STATUS.STARTED,
    }).pipe(map((json) => new Assessment(cloneDeep(json, true))));
  }

  exportAssessment(
    assessmentId: number,
    type: string,
    assessmentType: string,
  ): Observable<HttpResponse<Blob>> {
    let options;
    if (type === 'download') {
      options = {
        responseType: 'blob' as 'json',
        observe: 'response',
        ignoreCache: true,
      };
    }

    return this.get(
      `/assessment/${assessmentId}/export?type=${type}&assessmentType=${assessmentType}`,
      options,
    );
  }

  calculateAssessmentScore(assessmentId: number): Observable<AssessmentScore> {
    return this.get(`/assessment/${assessmentId}/score`).pipe(
      map((json) => new AssessmentScore(cloneDeep(json, true))),
    );
  }

  createAssessorComment(assessmentId: number, assessorComment: string): Observable<Assessment> {
    return this.post(`/assessment/${assessmentId}/comment`, { assessorComment }).pipe(
      map((json) => new Assessment(cloneDeep(json, true))),
    );
  }

  requestNewAssessment(facilityId: number) {
    return this.get(`/assessment/request-new?facilityId=${facilityId}`);
  }

  requestClosureOfGaps(facilityId: number) {
    return this.get(`/assessment/request-closure?facilityId=${facilityId}`);
  }

  getAllAssessmentsForFacility(facilityId: number): Observable<Assessment[]> {
    return this.get(`/assessment?facilityId=${facilityId}`).pipe(
      map((response) => response.map((assessment) => new Assessment(assessment))),
    );
  }
}
