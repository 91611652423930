<ng-template #popoverContent>
  <dl class="guidelines-popover-list">
    <ng-container *ngIf="question.information && question.information !== ''">
      <dt translate class="guidelines-popover-title">COMMON.GUIDELINES.INFORMATION</dt>
      <dd [innerHTML]="question.information"></dd>
    </ng-container>
  </dl>
</ng-template>

<ssa-popover
  *ngIf="question.information && question.information !== ''"
  [popoverContent]="popoverContent"
  [xPosition]="'before'"
  [yPosition]="'below'"
>
  <button mat-icon-button color="primary" class="guidelines-popover-btn">
    <mat-icon>info</mat-icon>
  </button>
</ssa-popover>
