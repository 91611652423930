import { Assessment } from '@models/assessment.model';
import { Component, Input, OnInit } from '@angular/core';
import { QuestionGroup } from '@models/question-group.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { QuestionnaireService } from '../questionnaire/providers/questionnaire.service';
import { QuestionValidator } from '@providers/question-validator/question-validator';
import { AssessmentQuestion } from '@shared/models/assessment-question.model';

@Component({
  selector: 'ssa-question-list',
  templateUrl: './question-list.component.html',
})
export class QuestionListComponent extends BaseComponent implements OnInit {
  @Input() public assessment: Assessment;

  public valid: boolean;
  public questionGroups: QuestionGroup[] = [];

  public constructor(
    private questionValidator: QuestionValidator,
    private questionnaireService: QuestionnaireService,
  ) {
    super();
  }

  ngOnInit(): void {
    const uniqueGroupIds: number[] = [];

    this.assessment.questions.forEach((q) => {
      if (q.questionGroup) {
        if (!uniqueGroupIds.find((id) => id === q.questionGroup.id)) {
          uniqueGroupIds.push(q.questionGroup.id);
        } else {
          q.questionGroup.groupAlreadyShow = true;
        }
      }
    });
  }

  verifyAllAnswered() {
    this.valid = this.questionValidator.validateAllQuestionsAnswered(this.assessment);
  }

  groupHasError(group: QuestionGroup): boolean {
    const questions = this.getQuestionsByGroupId(group.id);
    return questions.some((q) => q.error);
  }

  getQuestionsByGroupId(groupId: number): AssessmentQuestion[] {
    const result = this.assessment.questions.filter((q) => {
      if (q.questionGroup) {
        return q.questionGroup.id === groupId;
      }
      return false;
    });
    return result;
  }
}
