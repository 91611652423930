import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderDialogComponent } from '../../components/loader-dialog/loader-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loaderDialog: MatDialogRef<LoaderDialogComponent>;

  constructor(public dialog: MatDialog) {}

  start(): void {
    this.loaderDialog = this.dialog.open(LoaderDialogComponent);
  }

  stop(): void {
    if (this.loaderDialog) {
      this.loaderDialog.close();
    }
  }
}
