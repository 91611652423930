import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CacheHelper } from '../api/cache-helper';
import { Region } from '../../models/region.model';
import { CacheKeys } from '../../enums/cache-keys';
import { Country } from '../../models/country.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthApiService } from '../api/auth-api.service';
import { CountryApiService } from '../api/country-api.service';
import { FacilityApiService } from '../api/facility-api.service';
import { WhitelabelVersions } from '../../enums/whitelabel-versions';
import { FacilityRegistration } from '../../interfaces/facility-registration.interface';
import { District } from '@shared/models/district.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private authApiService: AuthApiService,
    private facilityService: FacilityApiService,
    private countryService: CountryApiService,
    private router: Router,
  ) {}

  private static moveCountryToBottom(countries: Country[]) {
    const otherIndex = countries.findIndex((c) => c.countryName === 'Other');

    if (otherIndex !== -1) {
      countries.push(countries.splice(otherIndex, 1)[0]);
    }
  }

  login(payload: { emailAddress: string; password: string; language: string }) {
    this.authApiService.login(payload.emailAddress, payload.password).subscribe((response) => {
      CacheHelper.writeToCache(CacheKeys.LOGGED_IN_USER, response.user);
      CacheHelper.writeToCache(CacheKeys.LOGGED_IN_ON, new Date());
      CacheHelper.writeToCache(CacheKeys.OAUTH_TOKEN, response.token);

      this.navigateUser(new User(response.user));
    });
  }

  private navigateUser(user: User) {
    if (user == null) return;

    let url = 'onboarding';
    if (user.isAdmin) {
      url = 'admin/home';
    } else if (user.isAssessor) {
      url = 'assessor/home';
    } else if (user.isOnboarded) {
      url = 'home';
    }

    this.router.navigateByUrl(url);
  }

  resetPassword(emailAddress: string): Observable<User> {
    return this.authApiService.resetPassword(emailAddress);
  }

  updatePassword(userId: number, password: string): Observable<User> {
    return this.authApiService.updatePassword(userId, password);
  }

  getUserByAssessorToken(assessorToken): Observable<User> {
    return this.authApiService.getUserByAssessorToken(assessorToken);
  }

  getUserByRegistrationToken(registrationToken): Observable<User> {
    return this.authApiService.getUserByRegistrationToken(registrationToken);
  }

  getLoggedInUser(): Observable<User> {
    const cachedUser = CacheHelper.fetchFromCache(CacheKeys.LOGGED_IN_USER);
    if (cachedUser != null) {
      return of(new User(cachedUser));
    }

    return of();
  }

  logout() {
    CacheHelper.clearStorage();

    this.router.navigateByUrl('/auth/login');
  }

  isAuthenticated(): Observable<boolean> {
    return of(CacheHelper.fetchFromCache(CacheKeys.LOGGED_IN_USER) != null);
  }

  getToken(): Observable<string> {
    return of(CacheHelper.fetchFromCache(CacheKeys.OAUTH_TOKEN));
  }

  getCountries(whitelabel: WhitelabelVersions = null): Observable<Country[]> {
    return this.countryService.getCountries(whitelabel).pipe(
      map((countries) => {
        AuthService.moveCountryToBottom(countries);

        return countries;
      }),
    );
  }

  getRegions(countryId: number): Observable<Region[]> {
    return this.countryService.getRegions(countryId);
  }

  getAllNigeriaRegions(): Observable<Region[]> {
    return this.countryService.getNigeriaRegions();
  }

  getDistrictsByRegionId(regionId: number): Observable<District[]> {
    return this.countryService.getDistrictsByRegionId(regionId);
  }

  register(registration: FacilityRegistration) {
    this.facilityService.selfRegisterFacility(registration).subscribe(() => {
      this.router.navigateByUrl('/auth/login');
    });
  }
}
