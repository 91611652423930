import { Component, OnInit } from '@angular/core';
import { IconService } from '@providers/icon/icon.service';
import { WorkerService } from '@providers/worker/worker.service';
import { BrowserService } from '@providers/browser/browser.service';
import Color from 'color';
import { WhitelabelService } from '@providers/api/whitelabel.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WhitelabelVersions } from './shared/enums/whitelabel-versions';

declare let gtag: (prop, tag, options) => void;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private iconService: IconService,
    private workerService: WorkerService,
    private browser: BrowserService,
    private whitelabelService: WhitelabelService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.iconService.registerIcons();
    this.workerService.checkNewVersion();
    this.browser.detectBrowser();
    this.changeTheme();
    this.setUpAnalytics();
  }

  changeTheme() {
    this.whitelabelService.getWhitelabelSettings().subscribe((settings) => {
      if (!settings) return;

      this.setColors(settings.primaryColor);
      this.setAppTitleColor();
    });
  }

  private setColors(primary: string) {
    const palette = this.computeColors(primary);

    for (const color of palette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  private setAppTitleColor() {
    const whitelabel = this.whitelabelService.getCurrentWhiteLabel();

    // If more whitelabels need a different app title, it is better to change the database
    if (whitelabel == WhitelabelVersions.PCN) {
      const key = '--theme-app-title';
      const value = '#fff'

      document.documentElement.style.setProperty(key, value);
    }
  }

  computeColors(hex: string): any[] {
    return [
      this.getColorObject(Color(hex).lighten(0.52), '50'),
      this.getColorObject(Color(hex).lighten(0.37), '100'),
      this.getColorObject(Color(hex).lighten(0.26), '200'),
      this.getColorObject(Color(hex).lighten(0.12), '300'),
      this.getColorObject(Color(hex).lighten(0.6), '400'),
      this.getColorObject(Color(hex), '500'),
      this.getColorObject(Color(hex).darken(0.6), '600'),
      this.getColorObject(Color(hex).darken(0.12), '700'),
      this.getColorObject(Color(hex).darken(0.18), '800'),
      this.getColorObject(Color(hex).darken(0.24), '900'),
      this.getColorObject(Color(hex).lighten(0.5).saturate(0.3), 'A100'),
      this.getColorObject(Color(hex).lighten(0.3).saturate(0.3), 'A200'),
      this.getColorObject(Color(hex).lighten(0.1).saturate(0.15), 'A400'),
      this.getColorObject(Color(hex).lighten(0.05).saturate(0.05), 'A700'),
      this.getColorObject(Color(hex).darken(0.6).fade(0.5), 'deep-sea-blue'),
      this.getColorObject(Color(hex).darken(0.8).fade(0.2), 'marine-blue'),
      this.getColorObject(Color(hex).lighten(0.2).fade(0.2), 'soft-blue'),
      this.getColorObject(Color(hex).lighten(0.9).fade(0.9).desaturate(0.5), 'border'),
      this.getColorObject(Color(hex).mix(Color('#fff'), 0.97).fade(0.9), 'hover'),
      this.getColorObject(Color(hex).mix(Color('#fff'), 0.96).fade(0.8), 'background'),
      this.getColorObject(Color('#373D3F'), 'text'),
    ];
  }

  getColorObject(color: Color, name) {
    return {
      name: name,
      hex: color.hex(),
      darkContrast: color.isLight(),
    };
  }

  setUpAnalytics() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'G-HRXFCS94QX', {
          page_path: event.urlAfterRedirects,
        });
      });
  }
}
