export class District {
  id: number;
  regionId: number;
  name: string;
  active: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.regionId = data.regionId;
      this.name = data.name;
      this.active = data.active;
    }
  }
}
