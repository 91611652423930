import { InjectionToken, FactoryProvider } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('window');
export const DOCUMENT = new InjectionToken<Document>('document');

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window,
};

const documentProvider: FactoryProvider = {
  provide: DOCUMENT,
  useFactory: () => document,
};

export const DOM_PROVIDERS = [windowProvider, documentProvider];
