import { Injectable } from '@angular/core';
import { AssessmentBaseService } from '../../shared/providers/assessment-base/assessment-base-service';
import { Assessment } from '../../shared/models/assessment.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService extends AssessmentBaseService {
  getLatestAssessment(
    facilityId: number = null,
    processStatus: string = null,
    ignoreCache = null,
    assessmentType: string = null,
  ): Observable<Assessment> {
    return this.assessmentService.getLatestAssessment(
      facilityId,
      processStatus,
      assessmentType,
      true,
      ignoreCache,
    );
  }

  requestNewAssessment(facilityId: number) {
    return this.assessmentService.requestNewAssessment(facilityId);
  }

  requestClosureOfGaps(facilityId: number) {
    return this.assessmentService.requestClosureOfGaps(facilityId);
  }
}
