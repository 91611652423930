import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Translation } from '../../interfaces/translation.interface';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationsApiService extends BaseApiService {
  getTranslationsByLanguage(language: string): Observable<any> {
    return this.get(`/translation?lang=${language}`, { ignoreCache: true });
  }

  getAllTranslations(ignoreWhitelabel = false): Observable<any> {
    return this.get(`/translation?ignoreWhitelabel=${ignoreWhitelabel}`, {
      ignoreCache: true,
    }).pipe(map((res) => res));
  }

  updateTranslation(translation: Translation): Observable<any> {
    return this.put(`/translation/${translation.id}`, translation);
  }
}
