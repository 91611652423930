import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Events } from '@providers/events/events';
import { DOM_PROVIDERS } from './tokens/dom.tokens';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '@providers/auth/auth.service';
import { IconService } from '@providers/icon/icon.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { RoleDirective } from './directives/role/role.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderService } from '@providers/loader/loader.service';
import { WorkerService } from '@providers/worker/worker.service';
import { SplitAndFirstUpper } from './pipes/split-and-first-upper';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { BrowserService } from '@providers/browser/browser.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { SettingsService } from '@providers/settings/settings.service';
import { AppBarComponent } from '@components/app-bar/app-bar.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuardService } from '@providers/auth-guard/auth-guard.service';
import { RoleGuardService } from '@providers/role-guard/role-guard.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SideMenuComponent } from '@components/side-menu/side-menu.component';
import { AuthInterceptor } from '@providers/auth-interceptor/auth-interceptor';
import { QuestionnairePage } from '@components/questionnaire/questionnaire.page';
import { NotificationService } from '@providers/notification/notification.service';
import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { NotificationComponent } from '@components/notification/notification.component';
import { LoaderDialogComponent } from '@components/loader-dialog/loader-dialog.component';
import { QuestionListComponent } from '@components/question-list/question-list.component';
import { QuestionViewerComponent } from '@components/question-viewer/question-viewer.component';
import { QuestionnaireService } from '@components/questionnaire/providers/questionnaire.service';
import { FacilityOverviewComponent } from '@components/facility-overview/facility-overview.component';
import { QuestionListPagerComponent } from '@components/question-list-pager/question-list-pager.component';
import { PopoverComponent } from '@components/question-viewer/information-popover/popover/popover.component';
import { SideMenuPostersComponent } from '@components/side-menu/side-menu-posters/side-menu-posters.component';
import { GuidelinesPopoverComponent } from '@components/question-viewer/information-popover/guidelines-popover.component';
import { PasswordStrengthValidatorComponent } from '@components/password-strength-validator/password-strength-validator.component';
import { SideMenuAdditionalResourcesComponent } from '@components/side-menu/side-menu-additional-resources/side-menu-additional-resources.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { DialogComponent } from '@components/dialog/dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { WhitelabelDirective } from '@shared/directives/whitelabel/whitelabel.directive';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

const modules = [
  FormsModule,
  CommonModule,
  RouterModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatMenuModule,
  MatInputModule,
  MatChipsModule,
  MatTableModule,
  TranslateModule,
  MatSelectModule,
  MatButtonModule,
  MatDialogModule,
  HttpClientModule,
  MatSidenavModule,
  MatToolbarModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatGridListModule,
  MatTabsModule,
  ReactiveFormsModule,
  MatProgressSpinnerModule,
  NgxGoogleAnalyticsModule,
  MatDatepickerModule,
  MatMomentDateModule,
];

const components = [
  DialogComponent,
  AppBarComponent,
  PopoverComponent,
  SideMenuComponent,
  QuestionnairePage,
  PageHeaderComponent,
  LoaderDialogComponent,
  NotificationComponent,
  QuestionListComponent,
  QuestionViewerComponent,
  SideMenuPostersComponent,
  FacilityOverviewComponent,
  QuestionListPagerComponent,
  GuidelinesPopoverComponent,
  PasswordStrengthValidatorComponent,
  SideMenuAdditionalResourcesComponent,
  ConfirmationDialogComponent,
];

const directives = [RoleDirective, WhitelabelDirective];

const pipes = [SplitAndFirstUpper];

const providers = [
  Events,
  AuthService,
  IconService,
  LoaderService,
  WorkerService,
  DOM_PROVIDERS,
  BrowserService,
  SettingsService,
  AuthGuardService,
  RoleGuardService,
  NotificationService,
  QuestionnaireService,
];

@NgModule({
  imports: [...modules],
  declarations: [...components, ...directives, ...pipes],
  exports: [...components, ...modules, ...directives, ...pipes],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...providers,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: {
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            autoFocus: false,
            panelClass: 'dialog',
          },
        },
        {
          provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
          useValue: {
            duration: 4000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            action: 'Close',
            panelClass: 'default',
          },
        },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: {
            floatLabel: 'always',
            hideRequiredMarker: true,
          },
        },
        {
          provide: MAT_RADIO_DEFAULT_OPTIONS,
          useValue: { color: 'primary' },
        },
        {
          provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
          useValue: { position: 'above' },
        },
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: true },
        },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
      ],
    };
  }
}
