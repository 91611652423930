<ng-container>
  <div class="questions__list" *ngIf="assessment">
    <div *ngFor="let question of assessment.questions; index as questionIndex">
      <div *ngIf="question.questionGroup">
        <div *ngIf="!question.questionGroup.groupAlreadyShow">
          <h1 [ngClass]="groupHasError(question.questionGroup) ? 'error' : ''">
            {{ question.questionGroup.name }}
          </h1>
          <div
            *ngFor="
              let question of getQuestionsByGroupId(question.questionGroup.id);
              index as questionIndex
            "
          >
            <ssa-question-viewer
              [(ngModel)]="question.answer"
              (ngModelChange)="verifyAllAnswered()"
              [question]="question"
              [assessmentType]="assessment.assessmentType"
              [questionIdx]="questionIndex"
            ></ssa-question-viewer>
          </div>
        </div>
      </div>

      <div *ngIf="!question.questionGroup">
        <ssa-question-viewer
          [(ngModel)]="question.answer"
          (ngModelChange)="verifyAllAnswered()"
          [question]="question"
          [assessmentType]="assessment.assessmentType"
          [questionIdx]="questionIndex"
        ></ssa-question-viewer>
      </div>
    </div>

    <div *ngIf="!valid" class="invalid-message mb-3">
      * {{ 'PAGES.ERRORS.ALL_REQUIRED_MESSAGE' | translate }}
    </div>
  </div>
</ng-container>
