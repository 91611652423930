import { EVENT } from '@shared/constants';
import { Location } from '@angular/common';
import { merge, Observable, of } from 'rxjs';
import { Events } from '@providers/events/events';
import { CacheKeys } from '@shared/enums/cache-keys';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { CacheHelper } from '@providers/api/cache-helper';
import { AuthService } from '@providers/auth/auth.service';
import { BaseComponent } from '@components/base/base.component';
import { SettingsService } from '@providers/settings/settings.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FacilityApiService } from '@providers/api/facility-api.service';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'ssa-app-bar',
  templateUrl: './app-bar.component.html',
})
export class AppBarComponent extends BaseComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'app-bar';

  headerLabel: string;
  isAuthenticated$: Observable<boolean>;
  isLoginPage = false;

  constructor(
    private auth: AuthService,
    private location: Location,
    private settings: SettingsService,
    private router: Router,
    private route: ActivatedRoute,
    private events: Events,
    private facilityService: FacilityApiService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.auth.isAuthenticated();

    if (window.addEventListener) {
      window.addEventListener(
        `storage_${CacheKeys.LOGGED_IN_USER}`,
        this.listenForCacheChanges,
        false,
      );
    }

    merge(
      of(this.route.snapshot.data),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          if (
            route.snapshot.data.pageTitle === 'PAGES.ASSESSMENT.TITLE' &&
            route.snapshot.queryParams.facilityId !== undefined
          ) {
            this.setFacilityHeader(route);
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        map((data) => data.pageTitle),
      ),
    ).subscribe((title) => {
      if (title instanceof Object) {
        return;
      }

      this.headerLabel = title;
      this.isLoginPage = window.location.href.includes('auth');
    });
  }

  toggleSideNav() {
    CacheHelper.writeToCache(CacheKeys.SIDE_MENU, true);
  }

  setFacilityHeader(route: ActivatedRoute) {
    this.facilityService
      .findById(route.snapshot.queryParams.facilityId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((facility) => this.checkFacilityHeaderLength(facility.facilityName));
  }

  checkFacilityHeaderLength(facilityName: string) {
    if (facilityName.length > 22) {
      this.headerLabel = facilityName.substring(0, 21) + '...';
    } else {
      this.headerLabel = facilityName;
    }
  }

  navigateBack() {
    if (
      this.router.url.includes('assessment') &&
      !this.router.url.includes('assessment/overview')
    ) {
      this.events.publish(EVENT.NAVIGATE_BACKWARDS);
    } else {
      this.location.back();
    }
  }

  private listenForCacheChanges = () => {
    this.isAuthenticated$ = this.auth.isAuthenticated();
  };

  ngOnDestroy(): void {
    super.ngOnDestroy();

    window.removeEventListener(
      `storage_${CacheKeys.LOGGED_IN_USER}`,
      this.listenForCacheChanges,
      false,
    );
  }
}
