import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { Poster } from '@models/poster.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PosterApiService extends BaseApiService {
  getAllPosters(): Observable<Poster[]> {
    return this.get(`/posters`).pipe(
      map((response) => response.map((poster) => new Poster(poster))),
    );
  }
}
