import { HostListener, Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { WINDOW } from '../../tokens/dom.tokens';
import { NotificationService } from '../notification/notification.service';

@Injectable({ providedIn: 'root' })
export class WorkerService {
  constructor(
    private _swUpdate: SwUpdate,
    private _notification: NotificationService,
    @Inject(WINDOW) private _window,
  ) {}

  checkNewVersion() {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.available.subscribe(() => {
        this._notification.onMessage({
          message: 'APP.NEW_VERSION',
          action: 'BUTTONS.RELOAD',
          simple: true,
          onAction: () => this._window.location.reload(),
          duration: null,
        });
      });
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  promptForInstall($event) {
    this._notification.onMessage({
      message: 'APP.INSTALL_NOTIFICATION',
      action: 'BUTTONS.INSTALL',
      simple: true,
      onAction: () => {
        if ($event) {
          $event.prompt();
        }
      },
      duration: null,
    });
  }
}
