<div class="side-menu-posters" *ngIf="posters && posters.length > 0">
  <h2 class="side-menu-posters__title">{{'MENU.POSTERS' | translate}}</h2>

  <ng-container *ngIf="posters.length > 0">
    <a *ngFor="let item of posters"
       [href]="item.url"
       target="_blank"
       class="side-menu-posters__poster"
       gaEvent
       [gaAction]="item.id"
       gaCategory="posters">

      <mat-icon [svgIcon]="item.svgIcon"></mat-icon>
      <span class="menu-label">{{item.titleTranslationKey | translate}}</span>
    </a>
  </ng-container>
</div>

