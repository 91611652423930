import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@providers/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@components/base/base.component';

@Directive({
  selector: '[ssaRole]',
})
export class RoleDirective extends BaseComponent implements OnInit {
  @Input() ssaRole: string[];

  constructor(
    private auth: AuthService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.auth
      .getLoggedInUser()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user) =>
        user && this.ssaRole.indexOf(user.userRole) !== -1
          ? this.viewContainer.createEmbeddedView(this.templateRef)
          : this.viewContainer.clear(),
      );
  }
}
