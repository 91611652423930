import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { AuthService } from '../auth/auth.service';
import { mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CacheHelper } from '../api/cache-helper';
import { CacheKeys } from '../../enums/cache-keys';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private settings: SettingsService;
  private auth: AuthService;
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    this.settings = this.injector.get(SettingsService);
    this.auth = this.injector.get(AuthService);

    // set the environment and auth headers
    const newHeaders = {
      'x-api-key': environment.apiKey,
    };

    return this.auth.getToken().pipe(
      take(1),
      mergeMap((token: string) => {
        // only add the Authorization header when there is a token and for API calls
        if (
          token &&
          req.url.indexOf(environment.apiUrl) !== -1 &&
          req.url.indexOf('/auth') === -1
        ) {
          newHeaders['Authorization'] = `Bearer ${token}`;
        }

        newHeaders['x-ssa-lang'] = CacheHelper.fetchFromCache(CacheKeys.CURRENT_LANGUAGE);

        // Clone the request to add the new headers.
        const authReq = req.clone({ setHeaders: newHeaders });

        // Pass on the cloned request instead of the original request.
        return next.handle(authReq);
      }),
    );
  }
}
