import { Component } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { WhitelabelService } from '@providers/api/whitelabel.service';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { WhitelabelVersions } from '../../../enums/whitelabel-versions';

@Component({
  selector: 'ssa-side-menu-additional-resources',
  templateUrl: './side-menu-additional-resources.component.html',
})
export class SideMenuAdditionalResourcesComponent extends BaseComponent {
  private readonly _additionalResources;

  constructor(private whitelabelService: WhitelabelService, private sanitizer: DomSanitizer) {
    super();

    this._additionalResources = [
      {
        id: 'navigate-safecare-website',
        titleTranslationKey: 'MENU.SAFECARE',
        url: this.sanitizer.bypassSecurityTrustUrl('https://www.safe-care.org/'),
        svgIcon: 'side-menu-website',
      },
    ];

    if (whitelabelService.getCurrentWhiteLabel() == WhitelabelVersions.PCN) {
      // NOTE: If more whitelables need links add urls to the database, and change them below
      this._additionalResources.push(
        {
          id: 'navigate-nhrs-website',
          titleTranslationKey: 'MENU.PCN_NHRS',
          url: this.sanitizer.bypassSecurityTrustUrl('https://www.nhrs.com.na/'),
          svgIcon: 'side-menu-website',
        }
      )

      this._additionalResources.push(
        {
          id: 'navigate-hpcna-website',
          titleTranslationKey: 'MENU.PCN_HPCNA',
          url: this.sanitizer.bypassSecurityTrustUrl('https://www.hpcna.com/'),
          svgIcon: 'side-menu-website',
        }
      )
    }

    whitelabelService
      .getWhitelabelSettings()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((settings) => {
        if (settings?.website) {
          this._additionalResources[0].url = this.sanitizer.bypassSecurityTrustUrl(
            settings.website,
          );
        }
      });
  }

  public get additionalResources() {
    return this._additionalResources;
  }
}
