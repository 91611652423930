export class Poster {
  id: number;
  key: string;
  titleTranslationKey: string;
  url: string;
  svgIcon: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.key = data.key;
      this.titleTranslationKey = data.titleTranslationKey;
      this.url = data.url;
      this.svgIcon = data.svgIcon;
    }
  }
}
