import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { User } from '../../models/user.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends BaseApiService {
  login(emailAddress: string, password: string): Observable<any> {
    return this.post(`/auth/login`, { emailAddress, password });
  }

  resetPassword(emailAddress: string): Observable<User> {
    return this.postAndMap(`/auth/password/reset`, { emailAddress });
  }

  updatePassword(userId: number, password: string): Observable<User> {
    return this.postAndMap(`/auth/password/update`, { userId, password });
  }

  private postAndMap(uri: string, body: any): Observable<User> {
    return this.post(uri, body).pipe(map((response) => new User(response)));
  }

  getUserByAssessorToken(assessorToken): Observable<User> {
    return this.get(`/auth/assessor/${assessorToken}/user`).pipe(
      map((response) => new User(response)),
    );
  }

  getUserByRegistrationToken(registrationToken): Observable<User> {
    return this.get(`/auth/user/${registrationToken}`).pipe(map((response) => new User(response)));
  }
}
