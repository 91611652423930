import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { WINDOW } from '../../tokens/dom.tokens';
import { AuthService } from '../auth/auth.service';
import { USER_ROLE } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    @Inject(WINDOW) private _window: any,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.getLoggedInUser().pipe(
      take(1),
      map((user) => {
        const url = this._window.location.href;
        const { isAllowed, redirectUrl } = this._isAllowed(user, url, route.data.roles);
        if (!isAllowed && redirectUrl) {
          this.router.navigate([redirectUrl]);
        }
        return isAllowed;
      }),
    );
  }

  private _isAllowed(
    user: User,
    url: string,
    roles: string[],
  ): { isAllowed: boolean; redirectUrl: string } {
    let isAllowed: boolean;
    let redirectUrl: string;

    if (roles) {
      // user should be logged in
      if (user) {
        // super admins always have access
        if (user.userRole === USER_ROLE.SUPER_ADMIN || roles.includes(USER_ROLE.ALL)) {
          isAllowed = true;
        } else {
          // role of current user should be part of data-role property of route
          isAllowed = roles.indexOf(user.userRole) !== -1;
          if (isAllowed === false) {
            redirectUrl = url.includes('admin') && user.isAdmin ? 'admin/home' : 'home';

            if (user.isAssessor) {
              redirectUrl = 'assessor/home';
            }
          }
        }
      } else {
        // there is no user, navigate to login
        redirectUrl = 'auth/login';
        isAllowed = false;
      }
    } else {
      // allow all if no roles are defined for route
      isAllowed = true;
    }

    return { isAllowed, redirectUrl };
  }
}
