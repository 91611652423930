export const SETTING = {
  ENVIRONMENT: 'environment',
  API_URL: 'apiUrl',
  API_KEY: 'apiKey',
  GOOGLE_API_KEY: 'googleApiKey',
  LANGUAGE: 'language',
  LANGUAGES: 'languages',
  ASSESSMENTS: 'assessments',
  NAMESPACE: 'namespace',
  EMAIL: 'email',
  SIDE_MENU: 'sideMenu',
  PAGE_TITLE: 'pageTitle',
  ANALYTICS: 'analytics',
  ASSESSMENT_TIMEOUT: 'assessmentTimeout',
};

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const EVENT = {
  NAVIGATE_BACKWARDS: 'assessment:navigateBacks',
};

export const QUESTION_TYPE = {
  OPEN: 'open',
  CLOSED: 'closed',
  CHOICE: 'choice',
  NUMBER: 'number',
  PERCENTAGE: 'percentage',
  PHONE: 'phone',
  EMAIL: 'email',
  DATE: 'date',
  SCORE: 'score',
  PARENT: 'parent',
};

export const ASSESSMENT_TYPE = {
  ONBOARDING: 'onboarding',
  CHECKLIST: 'checklist',
  ASSESSMENT: 'assessment',
};

export const PROCESS_STATUS = {
  STARTED: 'Started',
  FINISHED: 'Finished',
  SCORE: 'Scored',
  QIP: 'QIP',
};

export const USER_ROLE = {
  ALL: 'All',
  ADMIN: 'Admin',
  ASSESSOR: 'Assessor',
  SUPER_ADMIN: 'SuperAdmin',
  FACILITY_READ: 'FacilityRead',
  FACILITY_WRITE: 'FacilityWrite',
  FACILITY_QIP: 'FacilityQip',
};

export const REGEX = {
  PHONE: /^([+\d].*)?\d{9,16}$/,
  EMAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  PASSWORD: /^(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
};

export const REGISTRATION_TYPE = {
  SELF: 'self',
};

export const SCORE_TYPE = {
  FC: 'FC',
  PC: 'PC',
  NC: 'NC',
  NA: 'NA',
};

export const FACILITY_LOCATIONS = {
  RURAL: 'RURAL',
  URBAN: 'URBAN',
};

export const FACILITY_CATEGORIES = {
  SMALL: 'SMALL', // < 10 beds
  MEDIUM: 'MEDIUM', // < 10 - 50 beds
  LARGE: 'LARGE', // > 50 beds
};

export const FACILITY_TYPES = {
  GENERAL_HOSPITAL_WITH_MULTI_SPECIALTY_CARE: 'GENERAL_HOSPITAL_WITH_MULTI-SPECIALTY_CARE',
  SPECIALIZED_MATERNITY_CARE: 'SPECIALIZED_MATERNITY_CARE',
  MEDICAL_COLLEGE: 'MEDICAL_COLLEGE',
  PUBLIC_INSTITUTE: 'PUBLIC_INSTITUTE',
  GOVERNMENT_INSTITUTE: 'GOVERNMENT_INSTITUTE',
  CHARITABLE_HOSPITAL: 'CHARITABLE_HOSPITAL',
  OTHER: 'OTHER',
};

export const FACILITY_TYPES_NHIS = [
  'Pharmacy',
  'Diagnostic Centre',
  'Tertiary Hospital',
  'General Hospital',
  'Primary Health Centre',
  'Health Post',
  'Primary Health Clinic',
  'District Hospital',
  'Equipment Supplier',
  'Training Institution',
  'Support Service Companies',
  'Eye Clinic',
  'Dental Clinic',
  'Private Hospital',
];

export const OWNERSHIP_TYPES = ['Public', 'Private for Profit', 'Faith Based', 'NGO'];

export const PARTNER_ORGANIZATIONS = {
  JHPIEGO: 'JHPIEGO',
  ARTIST: 'ARTIST',
  PSI: 'PSI',
  HLFPPT: 'HLFPPT',
  CSE_AGRA: 'CSE_AGRA',
  CSE_NAVI_MUMBAI: 'CSE_NAVI_MUMBAI',
  CSE_LUCKNOW: 'CSE_LUCKNOW',
  CSE_DELHI: 'CSE_DELHI',
  CSE_NAGPUR: 'CSE_NAGPUR',
  CSE_PUNE: 'CSE_PUNE',
  CSE_GE_HEALTHCARE: 'CSE_GE_HEATHCARE',
  CSE_TECH_MAHINDRA: 'CSE_TECH_MAHINDRA',
  CSE_GORAKHPUR: 'CSE_GORAKHPUR',
  OTHER: 'OTHER',
};

export const FACILITY_SERVICES = [
  'Primary Provider - formal sector',
  'Primary Provider - BHCPF',
  'Cardiology',
  'Dental Services',
  'Dermatology',
  'Ear, Nose & Throat',
  'Echocardiography',
  'General Surgery',
  'Infectious diseases / Virology',
  'Laboratory',
  'Neonatology',
  'Nephrology',
  'Neurology',
  'Obstetrics & Gynaecology',
  'Ophthalmology',
  'Optometry',
  'Oral & Maxillofacial Surgery',
  'Orthopaedics',
  'Paediatrics',
  'Pharmacy',
  'Physiotherapy',
  'Psychiatry',
  'Radiology',
  'Ultrasonography',
];
