<div class="facility-overview">
  <table mat-table [dataSource]="facilities" class="mat-elevation-z8 facilities-table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'PAGES.FACILITIES.TABLE.NAME' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.facilityName }}</td>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef>{{ 'PAGES.FACILITIES.TABLE.EMAIL' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>{{ 'PAGES.FACILITIES.TABLE.COUNTRY' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.country }}</td>
    </ng-container>

    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef>{{ 'PAGES.FACILITIES.TABLE.REGION' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.region?.regionName }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'PAGES.FACILITIES.TABLE.TYPE' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
    </ng-container>

    <ng-container matColumnDef="iProvideId">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'PAGES.FACILITIES.TABLE.IPROVIDE_ID' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.iProvideId }}</td>
    </ng-container>

    <ng-container matColumnDef="assessmentDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'PAGES.FACILITIES.TABLE.LAST_ASSESSMENT_DATE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.lastAssessmentDate | date }}</td>
    </ng-container>

    <ng-container matColumnDef="obgynLead">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'PAGES.FACILITIES.TABLE.OBGYN_LEAD_NAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.obgynLead }}</td>
    </ng-container>

    <ng-container matColumnDef="assessorName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'PAGES.FACILITIES.TABLE.ASSESSOR_NAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.assessorEmail }}</td>
    </ng-container>

    <!-- TODO: Map to correct facility field -->
    <ng-container matColumnDef="lga">
      <th mat-header-cell *matHeaderCellDef>LGA</th>
      <td mat-cell *matCellDef="let element">{{ element.district?.name }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      class="facilities-page__table-row"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="rowClicked(row)"
    ></tr>
  </table>
</div>
