<div class="side-menu-additional-resources" *ngIf="additionalResources && additionalResources.length > 0">
  <h2 class="side-menu-additional-resources__title">{{'MENU.ADDITIONAL_RESOURCES' | translate}}</h2>

  <ng-container>
    <a *ngFor="let item of additionalResources"
       [href]="item.url"
       target="_blank"
       class="side-menu-additional-resources__item"
       gaEvent
       [gaAction]="item.id"
       gaCategory="additional-resources">

      <mat-icon [svgIcon]="item.svgIcon"></mat-icon>
      <span class="menu-label">{{item.titleTranslationKey | translate}}</span>
    </a>
  </ng-container>
</div>

