import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { getFileNameFromResponse } from '../../utils';
import { Facility } from '../../models/facility.model';
import { FacilitiesAsFiles } from '../../models/facilities-as-file.model';
import { FacilityRegistration } from '../../interfaces/facility-registration.interface';

@Injectable({
  providedIn: 'root',
})
export class FacilityApiService extends BaseApiService {
  fetchAllFacilities(): Observable<any> {
    return this.get('/facility', { ignoreCache: true }).pipe(
      map((response) => response.map((facility) => new Facility(facility))),
    );
  }

  selfRegisterFacility(registration: FacilityRegistration): Observable<any> {
    return this.post('/facility/register/self', registration);
  }

  createFacility(facility: Facility) {
    return this.post('/facility', facility);
  }

  fetchAllNHISFacilities(): Observable<any> {
    return this.get('/facility/nhis', { ignoreCache: true }).pipe(
      map((response) => response.map((facility) => new Facility(facility))),
    );
  }

  createFacilities(facilities: FacilitiesAsFiles) {
    const formData: FormData = new FormData();
    formData.append('whitelabel', facilities.whitelabel);
    formData.append('facilities', facilities.facilities, facilities.facilities.name);
    return this.post('/facility/create/fromFile', formData);
  }

  inviteAssessors(body) {
    return this.post('/facility/register/assessors', body);
  }

  inviteFacilityUsers(body) {
    return this.post('/facility/register/users', body);
  }

  findById(id: number): Observable<Facility> {
    return this.get(`/facility/${id}`).pipe(map((response) => new Facility(response)));
  }

  onBoardFacility(id: number, body: object): Observable<Facility> {
    return this.put(`/facility/${id}/on-board`, body).pipe(
      map((response) => new Facility(response)),
    );
  }

  updateFacilityStatus(body: any) {
    return this.put('/facility/update-status', body);
  }

  exportToExcel(): void {
    this.get(`/facility/export-to-excel`, {
      responseType: 'blob' as 'json',
      observe: 'response',
      ignoreCache: true,
    }).subscribe((blob) => {
      const fileName = getFileNameFromResponse(blob, 'facilities.xlsx');
      saveAs(blob.body, fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    });
  }
}
