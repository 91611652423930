export class UriHelper {
  public static addToHref(key: string, value: string | number): void {
    if (window.location.href.includes(key)) return;

    const separator = window.location.href.includes('?') ? '&' : '?';
    window.history.replaceState('', '', window.location.href + `${separator}${key}=${value}`);
  }

  public static removeFromHref(key: string, value: string | number): void {
    const uri = window.location.href
      .replace(`?${key}=${value}`, '')
      .replace(`&${key}=${value}`, '');

    window.history.replaceState('', '', uri);
  }
}
