import { Injectable } from '@angular/core';
import { CacheHelper } from '../api/cache-helper';
import { CacheKeys } from '@enums/cache-keys';
import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  get(key: string): any {
    return CacheHelper.fetchFromCache(key);
  }
  getEmailAddress(): string {
    const user = new User(CacheHelper.fetchFromCache(CacheKeys.LOGGED_IN_USER));

    return user?.emailAddress;
  }
}
