export class Country {
  id: number;
  countryName: string;
  active: boolean;
  phoneCode: string;
  selected: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.countryName = data.countryName;
      this.active = data.active;
      this.phoneCode = data.phoneCode;
      this.selected = data.selected;
    }
  }
}
