<div class="question-container" [class.child-question]="question.parentQuestionId" *ngIf="value">
  <div class="form-group" [ngSwitch]="question.questionType">
    <span class="error-tooltip" *ngIf="!valid && question.error">{{
      question.error | translate
    }}</span>
    <div
      class="form-label"
      [class]="question.questionType === 'parent' ? 'form-label--parent' : 'form-label--child'"
    >
      <div
        class="question-viewer__title-container"
        [class]="
          question.questionType === 'parent' ? 'question-viewer__title-container--parent' : ''
        "
      >
        <span class="question" [innerHTML]="getQuestionDescription(question)"></span>
        <ssa-guidelines-popover
          class="question-viewer__title-info-button"
          [question]="question"
        ></ssa-guidelines-popover>
      </div>
    </div>

    <input
      class="form-input"
      type="text"
      *ngSwitchCase="questionTypes.OPEN"
      [(ngModel)]="value.answer"
      (ngModelChange)="updateModel()"
    />

    <input
      class="form-input"
      type="date"
      *ngSwitchCase="questionTypes.DATE"
      [(ngModel)]="value.answer"
      (ngModelChange)="updateModel()"
    />

    <input
      class="form-input"
      type="number"
      *ngSwitchCase="questionTypes.NUMBER"
      placeholder="{{ 'COMMON.FORM_PLACEHOLDERS.NUMBER' | translate }}"
      [(ngModel)]="value.answer"
      (ngModelChange)="updateModel()"
    />

    <input
      class="form-input"
      type="email"
      *ngSwitchCase="questionTypes.EMAIL"
      [(ngModel)]="value.answer"
      (ngModelChange)="updateModel()"
    />

    <input
      class="form-input"
      type="phone"
      *ngSwitchCase="questionTypes.PHONE"
      [(ngModel)]="value.answer"
      (ngModelChange)="updateModel()"
    />

    <input
      class="form-input"
      type="number"
      min="0"
      max="100"
      *ngSwitchCase="questionTypes.PERCENTAGE"
      [(ngModel)]="value.answer"
      (ngModelChange)="updateModel()"
      placeholder="{{ 'COMMON.FORM_PLACEHOLDERS.NUMBER' | translate }}"
    />

    <ol
      *ngSwitchCase="questionTypes.CLOSED"
      class="form-options {{ assessmentType }}"
      [class.horizontal]="horizontalAlignment && question.answerOptions.length < 3"
    >
      <li
        *ngFor="let option of question.answerOptions; index as i"
        [ngClass]="{
          selected: value?.answerTemplateId === option.id,
          image: option.answerType === 'image'
        }"
      >
        <input
          type="radio"
          name="closed"
          [value]="option.id"
          [id]="'question_' + question.id + '_answer_' + i"
          [(ngModel)]="value.answerTemplateId"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="updateModelWithOption(option)"
          hidden
        />
        <label [for]="'question_' + question.id + '_answer_' + i">
          <span *ngIf="option.answerType === 'text'" class="option-label">{{ option.option }}</span>
          <span *ngIf="option.answerType === 'image'" class="option-image-container">
            <img class="option-image" [src]="'assets/images/' + option.imageUrl" />
            <span class="option-image-label">{{ option.option }}</span>
          </span>
        </label>
      </li>
    </ol>

    <ol
      *ngSwitchCase="questionTypes.CHOICE"
      class="form-options"
      [class.horizontal]="horizontalAlignment && question.answerOptions.length < 3"
    >
      <li
        *ngFor="let option of question.answerOptions; index as i"
        [class.selected]="checkChoice(question, option)"
      >
        <input
          type="checkbox"
          name="choice"
          [id]="'question_' + questionIdx + '_answer_' + i"
          [checked]="checkChoice(question, option)"
          (change)="setChoice(question, option, $event)"
          hidden
        />
        <label [for]="'question_' + questionIdx + '_answer_' + i">{{ option.option }}</label>
      </li>
    </ol>

    <div
      class="form-group"
      *ngIf="question.questionType !== 'parent' && value.isAnswered && showNote"
    >
      <p class="question-viewer__note-title">
        {{ 'PAGES.QUESTION.ADDITIONAL_NOTES' | translate }}
      </p>
      <textarea
        class="form-input question-viewer__note-container"
        [(ngModel)]="value.note"
        (ngModelChange)="updateModel()"
      ></textarea>
    </div>
  </div>
</div>
