import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth/auth.service';
import { BaseComponent } from '../base/base.component';
import { USER_ROLE } from '../../constants';
import { CacheHelper } from '../../providers/api/cache-helper';
import { CacheKeys } from '../../enums/cache-keys';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ssa-side-menu',
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  menuItems: MenuItem[];
  sideNav = false;

  private _allItems: MenuItem[];

  constructor(private authService: AuthService) {
    super();

    this._allItems = [
      {
        id: 'home',
        label: 'MENU.HOME',
        link: '/home',
        svgIcon: 'side-menu-home',
        userRoles: [USER_ROLE.FACILITY_READ, USER_ROLE.FACILITY_WRITE, USER_ROLE.FACILITY_QIP],
      },
      {
        id: 'home',
        label: 'MENU.HOME',
        link: '/admin/home',
        svgIcon: 'side-menu-home',
        userRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
      },
      {
        id: 'users',
        label: 'MENU.USERS',
        link: '/admin/users',
        icon: 'people',
        userRoles: [],
      },
      {
        id: 'translations',
        label: 'MENU.TRANSLATIONS',
        link: '/admin/translations',
        icon: 'language',
        userRoles: [USER_ROLE.SUPER_ADMIN],
      },
      {
        id: 'facilities',
        label: 'MENU.FACILITIES',
        link: '/admin/facilities',
        icon: 'dashboard',
        userRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
      },
      {
        id: 'home',
        label: 'MENU.HOME',
        link: '/assessor/home',
        svgIcon: 'side-menu-home',
        userRoles: [USER_ROLE.ASSESSOR],
      },
      {
        id: 'faq',
        label: 'MENU.FAQ',
        link: '/faq',
        svgIcon: 'side-menu-faq',
      },
      {
        id: 'contact',
        label: 'MENU.CONTACT',
        link: '/contact',
        svgIcon: 'side-menu-mail',
        userRoles: [USER_ROLE.FACILITY_WRITE, USER_ROLE.FACILITY_READ],
      },
    ];
  }

  ngOnInit(): void {
    if (window.addEventListener) {
      window.addEventListener(
        `storage_${CacheKeys.LOGGED_IN_USER}`,
        this.listenForCacheChanges,
        false,
      );
      window.addEventListener(`storage_${CacheKeys.SIDE_MENU}`, this.listenForCacheChanges, false);
    }
  }

  private listenForCacheChanges = () => {
    const open = CacheHelper.fetchFromCache(CacheKeys.SIDE_MENU);
    this.authService
      .getLoggedInUser()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user) => {
        this.isAuthenticated = user != null;
        this.menuItems = user
          ? this._allItems.filter(
              (_item) => _item.userRoles === undefined || _item.userRoles.includes(user.userRole),
            )
          : [];
      });

    this.sideNav = open === 'true' || open === true;
  };

  close() {
    CacheHelper.writeToCache(CacheKeys.SIDE_MENU, false);
  }

  logout() {
    this.authService.logout();
    this.close();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    window.removeEventListener(`storage_${CacheKeys.SIDE_MENU}`, this.listenForCacheChanges, false);
  }
}

interface MenuItem {
  id: string;
  label: string;
  link: string;
  icon?: string;
  svgIcon?: string;
  userRoles?: string[];
}
