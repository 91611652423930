import { HttpResponse } from '@angular/common/http';

export function convertDate(dateString: string): Date {
  if (isDate(dateString)) {
    const date: Date = new Date(dateString);
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
    );
  }

  return null;
}

export function isDate(dateString: string): boolean {
  return dateString !== null && !isNaN(Date.parse(dateString));
}

export function getFileNameFromResponse(res: HttpResponse<Blob>, defaultName: string) {
  const contentDisposition = (res && res.headers && res.headers.get('content-disposition')) || '';
  const matches = /filename=([^;]+)/gi.exec(contentDisposition);
  return matches && matches.length > 0 ? matches[1].trim() : defaultName;
}

export function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
}

export function setValue(obj, path, value) {
  const a = path.split('.');
  let o = obj;
  while (a.length - 1) {
    const n = a.shift();
    if (!(n in o)) {
      o[n] = {};
    }
    o = o[n];
  }
  o[a[0]] = value;
}

export function nullOrEmpty(input: string): boolean {
  return input === '' || input === null || input === undefined;
}
