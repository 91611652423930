export class AssessmentAnswerOption {
  id: number;
  questionId: number;
  option: string;
  score: string;
  imageUrl: string;
  answerType: string;

  constructor(data?: any, restore?: boolean) {
    if (data) {
      this.id = data.id;
      this.questionId = data.questionId;
      this.score = data.score;
      this.imageUrl = data.imageUrl;
      this.answerType = data.answerType;

      if (restore) {
        this.option = data.option;
      } else {
        this.option = data.description;
      }
    }
  }
}
