import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { PosterApiService } from '../../../providers/api/poster-api.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ssa-side-menu-posters',
  templateUrl: './side-menu-posters.component.html',
})
export class SideMenuPostersComponent extends BaseComponent implements OnInit {
  private _posters = [];

  constructor(private posterService: PosterApiService) {
    super();
  }

  ngOnInit(): void {
    this.posterService
      .getAllPosters()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((posters) => {
        this._posters = posters;
        this._posters.sort((a, b) => a.key.localeCompare(b.key));

        this.posters.forEach((poster) => {
          poster.url = poster.url.startsWith('http')
            ? poster.url
            : `./assets/posters/${poster.url}`;
        });
      });
  }

  public get posters() {
    return this._posters;
  }
}
