export class Category {
  id: number;
  categoryName: string;
  categoryType: string;
  versionId: number;
  order: number;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.categoryName = data.categoryName;
      this.categoryType = data.categoryType;
      this.versionId = data.versionId;
      this.order = data.order;
    }
  }
}
