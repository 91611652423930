<div class="password-validator">
  <div class="validation">
    <mat-icon [class.success]="characterCount">check_circle_outline</mat-icon>
    {{'PAGES.REGISTRATION.PASSWORD_VALIDATION_COUNT' | translate}}
  </div>

  <div class="validation">
    <mat-icon [class.success]="upperCase">check_circle_outline</mat-icon>
    {{'PAGES.REGISTRATION.PASSWORD_VALIDATION_UPPERCASE' | translate}}
  </div>

  <div class="validation">
    <mat-icon [class.success]="lowerCase">check_circle_outline</mat-icon>
    {{'PAGES.REGISTRATION.PASSWORD_VALIDATION_LOWERCASE' | translate}}
  </div>

  <div class="validation">
    <mat-icon [class.success]="numbers">check_circle_outline</mat-icon>
    {{'PAGES.REGISTRATION.PASSWORD_VALIDATION_NUMBERS' | translate}}
  </div>
</div>
