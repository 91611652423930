import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { Country } from '../../models/country.model';
import { catchError, map } from 'rxjs/operators';
import { Region } from '../../models/region.model';
import { WhitelabelVersions } from '../../enums/whitelabel-versions';
import { District } from '@shared/models/district.model';

@Injectable({
  providedIn: 'root',
})
export class CountryApiService extends BaseApiService {
  getCountries(whitelabel: WhitelabelVersions = null): Observable<Country[]> {
    let uri = `/country`;
    if (whitelabel != null) uri += `?whitelabel=${whitelabel}`;

    return this.get(uri).pipe(map((response) => response.map((country) => new Country(country))));
  }

  getRegions(countryId: number): Observable<any> {
    return this.get(`/country/${countryId}/regions`).pipe(
      map((response) => response.map((region) => new Region(region))),
      catchError(() => of([])),
    );
  }

  getNigeriaRegions(): Observable<any> {
    return this.get(`/country/nigeria/regions`).pipe(
      map((response) => response.map((region) => new Region(region))),
      catchError(() => of([])),
    );
  }

  getDistrictsByRegionId(regionId: number): Observable<any> {
    return this.get(`/district?regionId=${regionId}`).pipe(
      map((response) => response.map((district) => new District(district))),
      catchError(() => of()),
    );
  }
}
