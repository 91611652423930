import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Facility } from '../../models/facility.model';

@Component({
  selector: 'ssa-facility-overview',
  templateUrl: './facility-overview.component.html',
})
export class FacilityOverviewComponent implements OnInit {
  @Input() public facilities: Facility[];
  @Output() public facilitySelected = new EventEmitter<Facility>();

  @Input() public displayedColumns: string[] = [
    'name',
    'emailAddress',
    'country',
    'region',
    'type',
    'iProvideId',
    'assessmentDate',
  ];

  ngOnInit(): void {
    this.facilities = this.facilities?.sort((a, b) => (a.facilityName > b.facilityName ? 1 : 0));
  }

  rowClicked(row: Facility) {
    this.facilitySelected.emit(row);
  }
}
