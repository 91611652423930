<mat-menu #popover="matMenu" [xPosition]="xPosition" [yPosition]="yPosition" class="ssa-popover">
  <ng-template matMenuContent>
    <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" class="mat-popover">
      <!-- popover content begin -->
      <ng-container *ngTemplateOutlet="popoverContent"></ng-container>
      <!-- popover content end  -->
    </span>
  </ng-template>
</mat-menu>
<div [matMenuTriggerFor]="popover" class="trigger">
  <ng-content></ng-content>
</div>
