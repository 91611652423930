import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService } from './shared/providers/auth-guard/auth-guard.service';
import { RoleGuardService } from './shared/providers/role-guard/role-guard.service';
import { USER_ROLE } from './shared/constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuardService],
    data: {
      pageTitle: 'PAGES.HOME.TITLE',
      roles: [USER_ROLE.FACILITY_READ, USER_ROLE.FACILITY_WRITE],
    },
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqPageModule),
    data: {
      pageTitle: 'PAGES.FAQ.TITLE',
    },
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule),
    data: {
      pageTitle: 'PAGES.CONTACT.TITLE',
    },
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canActivate: [RoleGuardService, AuthGuardService],
    data: {
      pageTitle: 'PAGES.ONBOARDING.TITLE',
      roles: [USER_ROLE.ALL],
    },
  },
  {
    path: 'checklist',
    loadChildren: () => import('./checklist/checklist.module').then((m) => m.ChecklistModule),
    canActivate: [RoleGuardService, AuthGuardService],
    data: {
      pageTitle: 'PAGES.CHECKLIST.TITLE',
      roles: [USER_ROLE.FACILITY_WRITE, USER_ROLE.ASSESSOR],
    },
  },
  {
    path: 'assessment',
    loadChildren: () => import('./assessment/assessment.module').then((m) => m.AssessmentModule),
    canActivate: [RoleGuardService, AuthGuardService],
    data: {
      pageTitle: 'PAGES.ASSESSMENT.TITLE',
      roles: [USER_ROLE.FACILITY_WRITE, USER_ROLE.FACILITY_QIP, USER_ROLE.ASSESSOR],
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      pageTitle: 'PAGES.ADMIN.TITLE',
      roles: [USER_ROLE.ADMIN],
    },
  },
  {
    path: 'assessor',
    loadChildren: () => import('./assessor/assessor.module').then((m) => m.AssessorModule),
    data: {
      pageTitle: 'PAGES.ASSESSOR.TITLE',
      roles: [USER_ROLE.ASSESSOR],
    },
  },
  {
    path: 'user/check',
    loadChildren: () =>
      import('./pages/home/check/user-check.module').then((m) => m.UserCheckPageModule),
    data: {
      pageTitle: 'PAGES.USER_CHECK.TITLE',
    },
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
