import { Assessment } from '../../models/assessment.model';
import { Injectable } from '@angular/core';
import { AssessmentApiService } from '../api/assessment-api.service';
import { Observable } from 'rxjs';
import { getFileNameFromResponse } from '../../utils';
import { saveAs } from 'file-saver';
import { AssessmentAnswer } from '../../models/assessment-answer.model';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentBaseService {
  constructor(
    protected assessmentService: AssessmentApiService,
    private _notificationService: NotificationService,
  ) {}

  public getAssessmentById(id: number): Observable<Assessment> {
    return this.assessmentService.getAssessmentById(id);
  }

  public createAssessment(
    assessmentTemplateId: number,
    assessmentType: string,
    facilityId: number,
  ): Observable<Assessment> {
    return this.assessmentService.createAssessment(
      assessmentTemplateId,
      assessmentType,
      facilityId,
    );
  }

  public submitAnswers(assessment: Assessment): Observable<Assessment> {
    return this.assessmentService.answerAssessment(assessment);
  }

  public submitAnswerAsync(assessmentId: number, answer: AssessmentAnswer) {
    this.assessmentService.answerAssessmentQuestion(assessmentId, answer).subscribe();
  }

  public downloadAssessment(assessment: Assessment) {
    this.exportAndSubmit(assessment, 'download');
  }

  public mailAssessment(assessment: Assessment) {
    this.exportAndSubmit(assessment, 'email');
  }

  public download(assessmentId: number, assessmentType: string) {
    this.export(assessmentId, 'download', assessmentType);
  }

  public mail(assessmentId: number, assessmentType: string) {
    this.export(assessmentId, 'email', assessmentType);
  }

  private exportAndSubmit(assessment: Assessment, type: string) {
    this.submitAnswers(assessment).subscribe(() => {
      this.export(assessment.id, type, assessment.assessmentType);
    });
  }

  private export(assessmentId: number, type: string, assessmentType: string): void {
    this.assessmentService
      .exportAssessment(assessmentId, type, assessmentType)
      .subscribe((httpResponse) => {
        if (type !== 'download') {
          this._notificationService.onSuccess({
            message: 'NOTIFICATIONS.API.EMAIL_EXPORT_SUCCESS',
            simple: true,
          });
          return;
        }

        const fileName = getFileNameFromResponse(httpResponse, assessmentType);
        saveAs(httpResponse.body, fileName, { type: 'application/pdf' });
      });
  }
}
