import { CacheKeys } from '../../enums/cache-keys';
import { environment } from '../../../../environments/environment';

export class CacheHelper {
  public static writeToCache(key: CacheKeys | string, object: any): void {
    if (key == null || object == null) return;

    if (!environment.production) {
      // console.log(`Saving ${key} to cache: `, object);
    }

    if (typeof object === 'object') {
      object = JSON.stringify(object);
    }

    localStorage.setItem(key, object);
    window.dispatchEvent(new Event(`storage_${key}`));
  }

  public static fetchFromCache(key: CacheKeys | string, defaultValue?: any): any {
    const value = localStorage.getItem(key);
    if (!environment.production) {
      // console.log(`Fetching ${key} from cache: `, value);
    }

    if (value != null && !value.includes('{')) {
      return value;
    }

    return value != null ? JSON.parse(value) : defaultValue;
  }

  public static clearStorage() {
    const lang = this.fetchFromCache(CacheKeys.CURRENT_LANGUAGE, environment.language);
    localStorage.clear();
    this.writeToCache(CacheKeys.CURRENT_LANGUAGE, lang);
  }
}
