import { USER_ROLE } from '../constants';
import { Facility } from './facility.model';

export class User {
  id: number;
  facilityId: number;
  facility: Facility;
  firstName: string;
  lastName: string;
  userName: string;
  phoneNumber: string;
  emailAddress: string;
  userRole: string;
  isOnboarded: boolean;
  active: boolean;
  lastLogin: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.facilityId = data.facilityId;
      this.facility = data.facility ? new Facility(data.facility) : null;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.userName = data.userName;
      this.phoneNumber = data.phoneNumber;
      this.emailAddress = data.emailAddress;
      this.userRole = data.userRole;
      this.isOnboarded = data.isOnboarded;
      this.lastLogin = data.lastLogin ? new Date(data.lastLogin) : null;
      this.active = data.active;
    }
  }

  get fullName(): string {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : this.userName;
  }

  get isAdmin(): boolean {
    return this.userRole === USER_ROLE.ADMIN || this.userRole === USER_ROLE.SUPER_ADMIN;
  }

  get isAssessor(): boolean {
    return this.userRole === USER_ROLE.ASSESSOR;
  }

  get isQipUser(): boolean {
    return this.userRole === USER_ROLE.FACILITY_QIP;
  }
}
