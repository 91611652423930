import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'ssa-loader-dialog',
  templateUrl: './loader-dialog.component.html',
})
export class LoaderDialogComponent implements AfterViewInit {
  destroy = new Subject();

  constructor(public dialogRef: MatDialogRef<LoaderDialogComponent>) {}

  ngAfterViewInit(): void {
    this.dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
