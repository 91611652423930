import { ENVIRONMENT } from '../app/shared/constants';
import { WhitelabelVersions } from '../app/shared/enums/whitelabel-versions';

export const environment = {
  environment: ENVIRONMENT.PRODUCTION,
  testWhiteLabel: WhitelabelVersions.SSA,
  production: true,
  serviceWorker: false,
  showAssessmentTestButton: false,
  apiUrl: '/api',
  languages: [
    {
      label: 'English',
      code: 'en',
      default: true,
    },
    {
      label: 'Français',
      code: 'fr',
      default: false,
    },
  ],
  language: 'en',
  apiKey: '#4UbWF1w6kdi',
  googleApiKey: 'AIzaSyDWUs8W9HnAhJccvYyyxeMjwva6x3dpYMI',
  namespace: 'ssa',
  assessmentTimeout: 7,
  analytics: 'UA-144863893-2',
};
