import { QUESTION_TYPE } from '@shared/constants';
import { AssessmentAnswer } from '@models/assessment-answer.model';
import { AssessmentQuestion } from '@models/assessment-question.model';
import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { AssessmentAnswerOption } from '@models/assessment-answer-option.model';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'ssa-question-viewer',
  templateUrl: './question-viewer.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionViewerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QuestionViewerComponent),
      multi: true,
    },
  ],
})
export class QuestionViewerComponent implements ControlValueAccessor {
  private _value: AssessmentAnswer;

  @Input() question: AssessmentQuestion;
  @Input() assessmentType: string;
  @Input() questionIdx: number;
  @Input() horizontalAlignment = true;
  @Input() showNote = false;

  @HostBinding('class') class = 'question-viewer-component';
  questionTypes = QUESTION_TYPE;
  disabled: boolean;
  valid: boolean;

  get value(): AssessmentAnswer {
    this.valid = this._value ? this._value.isAnswered : false;
    return this._value;
  }

  set value(value: AssessmentAnswer) {
    this._value = value;
  }

  private _propagateChange: (_: any) => void;
  private _onTouched: () => void;

  writeValue(obj: any): void {
    this._value = obj;
  }

  updateModel() {
    this._propagateChange(this._value);
    this._onTouched();
  }

  updateModelWithOption(option: AssessmentAnswerOption) {
    this._value.score = option?.score;
    this._propagateChange(this._value);
    this._onTouched();
  }

  validate(c: FormControl) {
    if (c.touched) {
      return this.valid
        ? null
        : {
            required: {
              valid: false,
            },
          };
    }

    return null;
  }

  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  checkChoice(question: AssessmentQuestion, option: AssessmentAnswerOption): boolean {
    return question.checkChoice(option);
  }

  setChoice(question: AssessmentQuestion, option: AssessmentAnswerOption, event) {
    question.setChoice(option, event);
  }

  getQuestionDescription(question: AssessmentQuestion) {
    return question.fullNumber != null
      ? `${question.fullNumber} - ${question.description}`
      : question.description;
  }
}
