import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../constants';
import { BaseApiService } from './base-api.service';
import { environment } from '@environments/environment';
import { WhitelabelVersions } from '@enums/whitelabel-versions';
import { WhitelabelSettings } from '@models/whitelabel-settings.model';

@Injectable({
  providedIn: 'root',
})
export class WhitelabelService extends BaseApiService {
  private static devWhitelabel = environment.testWhiteLabel;

  public getWhitelabelSettings(): Observable<WhitelabelSettings> {
    const whitelabel = this.getCurrentWhiteLabel();

    if (whitelabel === WhitelabelVersions.SSA) return of(null);

    return this.get(`/white-labels?whitelabel=${whitelabel}`).pipe(
      map((response) => {
        if (response == null) return null;

        return new WhitelabelSettings(response);
      }),
    );
  }

  public isDev(): boolean {
    return environment.environment === ENVIRONMENT.DEVELOPMENT;
  }

  public getCurrentWhiteLabel(uri: string = window.location.origin): WhitelabelVersions {
    if (this.isDev()) return WhitelabelService.devWhitelabel;

    if (uri.includes('psa')) return WhitelabelVersions.PharmacySelfAssessment;
    if (uri.includes('kakamega')) return WhitelabelVersions.KAKAMEGA;
    if (uri.includes('business')) return WhitelabelVersions.BUSINESS;
    if (uri.includes('manyata-test')) return WhitelabelVersions.TEST_MANYATA;
    if (uri.includes('manyata')) return WhitelabelVersions.MANYATA;
    if (uri.includes('afghanistan')) return WhitelabelVersions.AFGHANISTAN;
    if (uri.includes('lamu')) return WhitelabelVersions.LAMU;
    if (uri.includes('nhis-internalmedicine')) return WhitelabelVersions.NHIS_INTERNAL_MEDICINE;
    if (uri.includes('nhis-surgery')) return WhitelabelVersions.NHIS_SURGERY;
    if (uri.includes('nhis-obsgyn')) return WhitelabelVersions.NHIS_OBSGYN;
    if (uri.includes('nhis-paediatrics')) return WhitelabelVersions.NHIS_PAEDIATRICS;
    if (uri.includes('nhis-radiology')) return WhitelabelVersions.NHIS_RADIOLOGY;
    if (uri.includes('nhis-laboratory')) return WhitelabelVersions.NHIS_LABORATORY;
    if (uri.includes('nhis-pharmacy')) return WhitelabelVersions.NHIS_PHARMACY;
    if (uri.includes('nhis-qa-bhcpf')) return WhitelabelVersions.NHIS_QA_BHCPF;
    if (uri.includes('nhis-qa')) return WhitelabelVersions.NHIS_QA;
    if (uri.includes('nhis')) return WhitelabelVersions.NHIS;
    if (uri.includes('nyeri')) return WhitelabelVersions.NYERI;
    if (uri.includes('heineken')) return WhitelabelVersions.HEINEKEN;
    if (uri.includes('kisumu')) return WhitelabelVersions.KISUMU;
    if (uri.includes('cssc')) return WhitelabelVersions.CSSC;
    if (uri.includes('ep')) return WhitelabelVersions.EP;
    if (uri.includes('ghs')) return WhitelabelVersions.GHS;
    if (uri.includes('pcn')) return WhitelabelVersions.PCN;

    return WhitelabelVersions.SSA;
  }

  public isManyata(): boolean {
    return (
      this.getCurrentWhiteLabel() === WhitelabelVersions.MANYATA ||
      this.getCurrentWhiteLabel() === WhitelabelVersions.TEST_MANYATA
    );
  }

  public isSsa(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.SSA;
  }

  public isKakamega(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.KAKAMEGA;
  }

  public isAfghanistan(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.AFGHANISTAN;
  }

  public isKisumu(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.KISUMU;
  }

  public isHeineken(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.HEINEKEN;
  }

  public isLamu(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.LAMU;
  }

  public isNHIS(): boolean {
    const currentWhitelabel = this.getCurrentWhiteLabel();
    return (
      currentWhitelabel === WhitelabelVersions.NHIS ||
      currentWhitelabel === WhitelabelVersions.NHIS_PHARMACY ||
      currentWhitelabel === WhitelabelVersions.NHIS_LABORATORY ||
      currentWhitelabel === WhitelabelVersions.NHIS_RADIOLOGY ||
      currentWhitelabel === WhitelabelVersions.NHIS_PAEDIATRICS ||
      currentWhitelabel === WhitelabelVersions.NHIS_OBSGYN ||
      currentWhitelabel === WhitelabelVersions.NHIS_SURGERY ||
      currentWhitelabel === WhitelabelVersions.NHIS_INTERNAL_MEDICINE ||
      currentWhitelabel === WhitelabelVersions.NHIS_QA_BHCPF ||
      currentWhitelabel === WhitelabelVersions.NHIS_QA
    );
  }
  public isNhisBhcpf(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.NHIS_QA_BHCPF;
  }
  public isCssc(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.CSSC;
  }
  public isNyeri(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.NYERI;
  }
  public isGhs(): boolean {
    return this.getCurrentWhiteLabel() === WhitelabelVersions.GHS;
  }
}
