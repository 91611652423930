import { QIPActivity } from './qip-activity.model';

export class QIPQuestion {
  id: number;
  question: string;
  category: string;
  activities: QIPActivity[];

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.activities = data.activities
        ? data.activities.map((activity) => new QIPActivity(activity))
        : [];
      this.question = data.description;
      this.category = data.category;
    }
  }

  get activitiesDone() {
    return this.activities
      ? this.activities.filter((_activity) => _activity.checked === false).length === 0
      : true;
  }
}
