<ng-container>
  <!-- Confirmation intro, new assessment will be created after submit -->
  <div class="onboarding-info-page mb-3" *ngIf="showInformationPage && !loading">
    <ssa-page-header>
      <h2 translate slot="subtitle">PAGES.{{assessmentType?.toUpperCase()}}.INFORMATION_TITLE</h2>
    </ssa-page-header>

    <div class="info-content">
      <div
        [innerHTML]="'PAGES.' + assessmentType?.toUpperCase() + '.INFORMATION_TEXT' | translate"
      ></div>
      <button
        mat-raised-button
        color="primary"
        (click)="confirmInfoPage()"
        class="icon-btn full-width"
        [disabled]="assessmentTemplate?.id == null"
      >
        <span class="icon-btn-label">{{'BUTTONS.GET_STARTED' | translate}}</span>
        <mat-icon svgIcon="login_arrow"></mat-icon>
      </button>
    </div>
  </div>

  <!-- Show assessment questions -->
  <ng-container *ngIf="!showInformationPage && !loading && assessment">
    <div class="page-content onboarding-page">
      <h3 class="assessment-subtitle assessment-subtitle--optional subtitle">
        {{'PAGES.' + assessmentType?.toUpperCase() + '.SUB_TITLE' | translate}}
      </h3>

      <ssa-question-list *ngIf="!singlePageQuestions" [assessment]="assessment"></ssa-question-list>
      <ssa-question-list-pager
        *ngIf="singlePageQuestions"
        [assessment]="assessment"
        (assessmentSubmittedEvent)="handleAssessmentSubmitted($event)"
        [isAssessor]="user.isAssessor"
      ></ssa-question-list-pager>

      <div class="export-button-bar" *ngIf="exportable">
        <button mat-flat-button class="export-button" (click)="download()">
          <mat-icon svgIcon="download"></mat-icon>
          <span class="button-label">{{"BUTTONS.DOWNLOAD_CHECKLIST" | translate}}</span>
        </button>
        <button mat-flat-button class="export-button" (click)="mail()">
          <mat-icon matPrefix svgIcon="email"></mat-icon>
          {{"BUTTONS.MAIL_CHECKLIST" | translate}}
        </button>
      </div>

      <div class="navigation-bar" *ngIf="multiButtonLayout">
        <button
          mat-flat-button
          color="primary"
          class="submit-button"
          (click)="verifyAndNavigate('/home')"
        >
          {{"BUTTONS.BACK_TO_HOME" | translate}}
        </button>

        <button
          mat-flat-button
          color="accent"
          class="submit-button"
          (click)="verifyAndNavigate('/assessment')"
        >
          {{"BUTTONS.START_ASSESSMENT" | translate}}
        </button>
      </div>

      <div *ngIf="!multiButtonLayout && assessmentType !== 'assessment' && !user.isAssessor">
        <button
          mat-flat-button
          color="accent"
          class="icon-btn form-button"
          (click)="verifyAndNavigate('/home')"
        >
          <span class="icon-btn-label"
            >{{"BUTTONS.SAVE_" + assessmentType?.toUpperCase() | translate}}</span
          >
          <mat-icon svgIcon="login_arrow"></mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
