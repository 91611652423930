import { User } from '@models/user.model';
import { Assessment } from '@models/assessment.model';
import { UriHelper } from '@shared/helpers/uri-helper';
import { BaseComponent } from '../base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentTypes } from '@shared/enums/assessment-types';
import { AuthService } from '@shared/providers/auth/auth.service';
import { AssessmentAnswer } from '@models/assessment-answer.model';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { QuestionnaireService } from './providers/questionnaire.service';
import { QuestionValidator } from '@shared/providers/question-validator/question-validator';
import { LoaderService } from '@shared/providers/loader/loader.service';
import { WhitelabelService } from '@shared/providers/api/whitelabel.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ssa-questionnaire-page',
  templateUrl: './questionnaire.page.html',
  styleUrls: ['./questionnaire.page.scss'],
})
export class QuestionnairePage extends BaseComponent implements OnInit {
  @HostBinding('class') class = 'page';

  @Input() continuedAssessment: Assessment;
  @Input() assessmentType: AssessmentTypes;
  @Input() multiButtonLayout = false;
  @Input() singlePageQuestions = false;
  @Input() exportable = false;

  assessmentTemplate: Assessment;
  user: User;
  assessment: Assessment;
  showInformationPage = true;
  loading = true;
  valid: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private questionValidator: QuestionValidator,
    private questionnaireService: QuestionnaireService,
    private loaderService: LoaderService,
    private whitelabelService: WhitelabelService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loaderService.start();

    this.prepareQuestions();
  }

  public confirmInfoPage() {
    let facilityId = this.user.facilityId;
    if (this.user.isAssessor) {
      facilityId = this.route.snapshot.queryParams['facilityId'];
    }

    this.questionnaireService
      .createAssessment(
        this.assessmentTemplate.assessmentTemplateId,
        this.assessmentType,
        facilityId,
      )
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((newAssessment: Assessment) => {
        if (newAssessment == null) {
          return;
        }

        if (this.user.isAssessor) {
          this.multiButtonLayout = false;
          this.singlePageQuestions = true;
          this.exportable = false;
        }

        UriHelper.addToHref('assessmentId', newAssessment.id);

        this.showInformationPage = false;
        newAssessment.questions = this.assessmentTemplate.questions;
        this.assessment = newAssessment;
      });
  }

  public verifyAndNavigate(url: string, extras = {}) {
    if (!this.validateForm()) {
      return;
    }

    this.loaderService.start();
    this.questionnaireService
      .submitAnswers(this.assessment)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this.loaderService.stop();
        this.router.navigateByUrl(url, extras);
      });
  }

  public handleAssessmentSubmitted(event: any) {
    this.verifyAndNavigate(`/assessment/${event.id}/score`, {
      state: {
        numberOfCategories: event.numberOfCategories,
        numberOfCategoriesFullyAnswered: event.numberOfCategoriesFullyAnswered,
      },
    });
  }

  public download() {
    if (!this.validateForm()) {
      return;
    }

    this.questionnaireService.downloadAssessment(this.assessment);
  }

  public mail() {
    if (!this.validateForm()) {
      return;
    }

    this.questionnaireService.mailAssessment(this.assessment);
  }

  private continueAssessment() {
    const continueAssessmentId = this.route.snapshot.queryParams['assessmentId'];
    if (!this.continuedAssessment && !continueAssessmentId) {
      this.stopLoading();

      return;
    }

    if (this.continuedAssessment) {
      this.assessment = this.continuedAssessment;
      this.setupContinuedAssessment();
    } else {
      this.questionnaireService
        .getAssessmentById(continueAssessmentId)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe((assessment) => {
          this.assessment = assessment;
          this.continuedAssessment = assessment;
          this.setupContinuedAssessment();
        });
    }
  }

  private prepareQuestions() {
    this.questionnaireService
      .getAssessmentQuestions(this.assessmentType)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res: Assessment) => {
        this.assessmentTemplate = res;
        this.prepare();
      });
  }

  private stopLoading() {
    this.loading = false;
    this.loaderService.stop();

    setTimeout(() => {
      const page = document.querySelector('.page-content');
      if (page) page.scrollTop = 0;
    }, 2);
  }

  private prepare() {
    this.authService
      .getLoggedInUser()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        this.user = res;
        this.continueAssessment();
      });
  }

  private setupContinuedAssessment() {
    this.continuedAssessment.questions = this.assessmentTemplate.questions;
    this.setAnswersForContinuedAssessment();
    this.showInformationPage = false;
    this.stopLoading();
  }

  private setAnswersForContinuedAssessment() {
    this.continuedAssessment?.questions.forEach((q) => {
      const answer = this.findAnswerByQuestionId(q.id);
      if (answer != null) {
        q.answer = answer;
      }
    });
  }

  private findAnswerByQuestionId(questionId: number) {
    return this.continuedAssessment.answers?.find((a) => a.questionTemplateId === questionId);
  }

  private validateForm(): boolean {
    this.valid = this.questionValidator.isAssessmentValid(this.assessment);

    return this.valid;
  }
}
