import { District } from './district.model';
import { FacilitySettings } from './facility-settings.model';
import { Region } from './region.model';

export class Facility {
  id: number;
  facilityName: string;
  address: string;
  regionId: number;
  region: Region;
  districtId: number;
  district: District;
  facilityType: string;
  ownershipType: string;
  locationType: string;
  latitude: number;
  longitude: number;
  emailAddress: string;
  alternativeEmailAddress: string;
  phoneNumber: string;
  phoneNumber2: string;
  numberOfClinicalStaff: number;
  bankName: string;
  accountName: string;
  accountNumber: number;
  facilityLicense: number;
  CACRegistration: number;
  medicalDirectorName: string;
  folioNumberMedicalDirector: number;
  nameFirstRegisteredMidwife: string;
  numberFirstRegisteredMidwife: number;
  nameSecondRegisteredMidwife: string;
  numberSecondRegisteredMidwife: number;

  iProvideId: number;
  country: string;
  facilitySettings: FacilitySettings;
  contactPerson: string;
  fogsiMember: boolean;
  healthCareLevel: string;
  lastAssessmentDate: Date;
  dateAssessmentPlanned: Date;
  assessorName: string;
  assessorEmail: string;
  location: string;
  category: string;
  partnerOrganization: string;
  maternityBeds: string;
  establishedIn: number;
  obgynLead: string;
  obgynLeadFogsiMember: boolean;
  fogsiNpmuCoordinator: string;
  previousAssessmentScore: number;
  previousAssessmentScoreOutOf: number;
  previousAssessmentBy: string;
  services: string[];
  active: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.iProvideId = data.iProvideId;
      this.facilityName = data.facilityName;
      this.country = data.country;
      this.region = data.region;
      this.district = data.district;
      this.locationType = data.locationType;
      this.facilityType = data.facilityType;
      this.emailAddress = data.emailAddress;
      this.alternativeEmailAddress = data.alternativeEmailAddress;
      this.facilitySettings = data.facilitySettings
        ? new FacilitySettings(data.facilitySettings)
        : null;
      this.active = data.active;
      this.address = data.address;
      this.contactPerson = data.contactPerson;
      this.fogsiMember = data.fogsiMember;
      this.healthCareLevel = data.healthCareLevel;
      this.phoneNumber = data.phoneNumber;
      this.lastAssessmentDate = data.lastAssessmentDate;
      this.assessorName = data.assessorName;
      this.assessorEmail = data.assessorEmail;
      this.location = data.location;
      this.category = data.category;
      this.partnerOrganization = data.partnerOrganization;
      this.maternityBeds = data.maternityBeds;
      this.establishedIn = data.establishedIn;
      this.obgynLead = data.obgynLead;
      this.obgynLeadFogsiMember = data.obgynLeadFogsiMember;
      this.fogsiNpmuCoordinator = data.fogsiNpmuCoordinator;
      this.previousAssessmentScore = data.previousAssessmentScore;
      this.previousAssessmentScoreOutOf = data.previousAssessmentScoreOutOf;
      this.previousAssessmentBy = data.previousAssessmentBy;

      this.ownershipType = data.ownershipType;
      this.regionId = data.regionId;
      this.region = data.region;
      this.districtId = data.districtId;
      this.district = data.district;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.phoneNumber2 = data.phoneNumber2;
      this.numberOfClinicalStaff = data.numberOfClinicalStaff;
      this.bankName = data.bankName;
      this.accountName = data.accountName;
      this.accountNumber = data.accountNumber;
      this.facilityLicense = data.facilityLicense;
      this.CACRegistration = data.CACRegistration;
      this.medicalDirectorName = data.medicalDirectorName;
      this.folioNumberMedicalDirector = data.folioNumberMedicalDirector;
      this.nameFirstRegisteredMidwife = data.nameFirstRegisteredMidwife;
      this.numberFirstRegisteredMidwife = data.numberFirstRegisteredMidwife;
      this.nameSecondRegisteredMidwife = data.nameSecondRegisteredMidwife;
      this.numberSecondRegisteredMidwife = data.numberSecondRegisteredMidwife;
      // data.services is a comma separated string
      this.services = data.services ? data.services.split(',') : null;
    }
  }

  get isOnboarded(): boolean {
    return (
      this.facilityType != null &&
      this.location != null &&
      this.category != null &&
      this.partnerOrganization != null
    );
  }
}
