import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Assessment } from '../../../models/assessment.model';
import { AssessmentTypes } from '../../../enums/assessment-types';
import { AssessmentBaseService } from '../../../providers/assessment-base/assessment-base-service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService extends AssessmentBaseService {
  getAssessmentQuestions(assessmentType: AssessmentTypes): Observable<Assessment> {
    return this.assessmentService.getAssessmentQuestions(assessmentType);
  }
}
