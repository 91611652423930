export class AssessmentScore {
  score: number;
  scoreText: string;

  constructor(data?: any, restore?: boolean) {
    if (data) {
      this.score = restore ? data.score : Math.round(data.score);
      this.scoreText = data.scoreText;
    }
  }
}
