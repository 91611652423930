export class Region {
  id: number;
  countryId: number;
  regionName: string;
  active: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.regionName = data.regionName;
      this.active = data.active;
      this.countryId = data.countryId;
    }
  }
}
