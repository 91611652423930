import { QIPQuestion } from './qip-question.model';

export class QIP {
  questions: QIPQuestion[];

  constructor(data?: any) {
    if (data) {
      this.questions = data.questions
        ? data.questions.map((question) => new QIPQuestion(question))
        : [];
    }
  }
}
