import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'splitandfirstupper' })
export class SplitAndFirstUpper implements PipeTransform {
  transform(value: string): any {
    const words = [];
    value.split('_').forEach((word) => {
      words.push(word[0].toUpperCase() + word.substr(1).toLowerCase());
    });

    return words.join(' ');
  }
}
