import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslationsApiService } from '../api/translations-api.service';

export class LanguageLoader implements TranslateLoader {
  constructor(private translationsService: TranslationsApiService) {}

  getTranslation(lang: string): Observable<any> {
    return this.translationsService.getTranslationsByLanguage(lang);
  }
}
