import { AssessmentQuestion } from './assessment-question.model';
import { PROCESS_STATUS } from '../constants';
import { AssessmentCategory } from './assessment-category.model';
import { AssessmentAnswer } from './assessment-answer.model';
import { User } from './user.model';

export class Assessment {
  id: number;
  facilityId: number;
  dateAssessmentPlanned: Date;
  startDate: Date;
  endDate: Date;
  processStatus: string;
  categories: AssessmentCategory[];
  questions: AssessmentQuestion[];
  answers: AssessmentAnswer[];
  assessmentType: string;
  assessmentTemplateId: number;
  score: number;
  categoryScore: string;
  finishedByUser: User;

  constructor(data?: any, restore?: boolean) {
    if (data) {
      this.id = data.id;
      this.facilityId = data.facilityId;
      this.startDate = data.startDate ? new Date(data.startDate) : null;
      this.endDate = data.endDate ? new Date(data.endDate) : null;
      this.processStatus = data.processStatus;
      this.assessmentType = data.assessmentType;
      this.questions = data.questions
        ? data.questions.map((question) => new AssessmentQuestion(question, restore))
        : [];
      this.answers = data.answers?.map((answer) => new AssessmentAnswer(answer, restore));
      this.assessmentTemplateId = data.assessmentTemplateId ?? data.id;
      this.score = data.score;
      this.categoryScore = data.categoryScore;
      this.finishedByUser = new User(data.finishedByUser);
      this.dateAssessmentPlanned = data.plannedDate;
      this.categories = data.categories;
    }
  }

  get readonly(): boolean {
    return this.processStatus === PROCESS_STATUS.FINISHED;
  }
}
