import { AssessmentQuestion } from './assessment-question.model';

export class AssessmentCategory {
  id: number;
  categoryName: string;
  categoryType: string;
  order: number;
  questions: AssessmentQuestion[];

  constructor(data?: any, restore = false) {
    if (data) {
      this.id = data.id;
      this.categoryName = data.categoryName;
      this.categoryType = restore ? data.categoryType : data.categoryType.toLowerCase();
      this.order = data.order;
      this.questions = data.questions;
    }
  }
}
