export enum WhitelabelVersions {
  SSA = 'ssa',
  MANYATA = 'manyata',
  TEST_MANYATA = 'test-manyata',
  KAKAMEGA = 'kakamega',
  KISUMU = 'kisumu',
  BUSINESS = 'business',
  AFGHANISTAN = 'afghanistan',
  HEINEKEN = 'heineken',
  CSSC = 'cssc',
  NYERI = 'nyeri',
  PharmacySelfAssessment = 'PSA',
  LAMU = 'lamu',
  NHIS = 'nhis',
  EP = 'ep',
  GHS = 'ghs',
  PCN = 'pcn',
  NHIS_INTERNAL_MEDICINE = 'nhis_internal_medicine',
  NHIS_SURGERY = 'nhis_surgery',
  NHIS_OBSGYN = 'nhis_obsgyn',
  NHIS_PAEDIATRICS = 'nhis_paediatrics',
  NHIS_RADIOLOGY = 'nhis_radiology',
  NHIS_LABORATORY = 'nhis_laboratory',
  NHIS_PHARMACY = 'nhis_pharmacy',
  NHIS_QA_BHCPF = 'nhis_qa_bhcpf',
  NHIS_QA = 'nhis_qa',
  KWARA = 'kwara',
  EDO_STATE = 'edo_state',
  NHIA = 'nhia',
}
