import { Component, OnInit } from '@angular/core';
import { AuthService } from '@providers/auth/auth.service';
import { WhitelabelService } from '@providers/api/whitelabel.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@components/base/base.component';

@Component({
  selector: 'ssa-page-header',
  templateUrl: './page-header.component.html',
})
export class PageHeaderComponent extends BaseComponent implements OnInit {
  headerImage = '/assets/images/SafeCare_background.png';
  bannerBrighter = true;

  constructor(private authService: AuthService, private whitelabelService: WhitelabelService) {
    super();
  }

  ngOnInit(): void {
    this.prepareHeaderImage();
    this.setWhiteLabel();
  }

  prepareHeaderImage(): void {
    this.authService
      .getLoggedInUser()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user) => {
        if (!user || !user.facility || !user.facility.facilitySettings) {
          return;
        }

        this.setBannerImage(user.facility.facilitySettings.headerImage);
      });
  }

  private setWhiteLabel() {
    this.whitelabelService
      .getWhitelabelSettings()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((settings) => {
        if (!settings) return;

        this.setBannerImage(settings.bannerImage);
      });
  }

  private setBannerImage(image: string) {
    this.headerImage = '/assets/images/' + image;
  }

  public getPageHeader() {
    if (this.whitelabelService.isHeineken()) {
      this.bannerBrighter = false;
      return 'page-header-brighter';
    } else {
      return '';
    }
  }

  get classModifierForWhitelabel() {
    return this.whitelabelService.isSsa() ? 'banner--darker' : '';
  }
}
