import { AssessmentAnswer } from './assessment-answer.model';
import { AssessmentAnswerOption } from './assessment-answer-option.model';
import { AssessmentCategory } from './assessment-category.model';
import { QUESTION_TYPE } from '../constants';
import { QuestionGroup } from './question-group.model';

export class AssessmentQuestion {
  id: number;
  description: string;
  fullNumber: string;
  information: string;
  questionType: string;
  answer: AssessmentAnswer;
  answerOptions: AssessmentAnswerOption[];
  required: boolean;
  tip: string;
  category: AssessmentCategory;
  error: string;
  parentQuestionId: number;
  skipTo: number;
  skipWhen: string;
  skipValue: number;
  childQuestions: AssessmentQuestion[];
  questionGroup: QuestionGroup;

  constructor(data?: any, restore?: boolean) {
    if (data) {
      this.id = data.id;
      this.skipTo = data.skipTo;
      this.skipWhen = data.skipWhen;
      this.skipValue = data.skipValue;
      this.answerOptions = data.answerOptions
        ? data.answerOptions.map((option) => new AssessmentAnswerOption(option, restore))
        : [];
      this.required = data.required || true;
      this.tip = data.tip;
      this.category = data.category ? new AssessmentCategory(data.category) : null;
      this.description = data.description;
      this.fullNumber = data.fullNumber;
      this.information = data.information;
      this.questionType = data.questionType;
      this.parentQuestionId = data.parentQuestionId;
      this.answer = data.answer
        ? new AssessmentAnswer(data.answer, restore)
        : new AssessmentAnswer({ questionTemplateId: data.id });
      this.questionGroup = data.questionGroup;
    }
  }

  checkRadio(optionId: number): boolean {
    if (this.answer) {
      return this.answer.answerTemplateId === optionId;
    }
    return false;
  }

  setRadio(optionId: number) {
    if (this.answer) {
      this.answer.answerTemplateId = optionId;
    }
  }

  checkChoice(option: AssessmentAnswerOption): boolean {
    if (this.answer && this.answer.answer) {
      return this.answer.answer.indexOf(option) !== -1;
    }
  }

  setChoice(option: AssessmentAnswerOption, event) {
    if (this.answer) {
      const index = this.answer.answer.indexOf(option);
      if (event.target.checked) {
        if (index === -1) {
          this.answer.answer.push(option);
        }
      } else {
        if (index !== -1) {
          this.answer.answer.splice(index, 1);
        }
      }
    }
  }

  get isAnswered(): boolean {
    return this.answer.isAnswered || this.questionType === QUESTION_TYPE.PARENT;
  }
}
