import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EVENT } from '@shared/constants';
import { QuestionQueue } from './question-queue';
import { Events } from '@providers/events/events';
import { Assessment } from '@models/assessment.model';
import { environment } from '@environments/environment';
import { AssessmentAnswer } from '@models/assessment-answer.model';
import { WhitelabelService } from '@providers/api/whitelabel.service';
import { AssessmentQuestion } from '@models/assessment-question.model';
import { QuestionValidator } from '@providers/question-validator/question-validator';
import { AssessmentService } from '../../../assessment/providers/assessment.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssessmentCategory } from '@shared/models/assessment-category.model';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ssa-question-list-pager',
  templateUrl: './question-list-pager.component.html',
})
export class QuestionListPagerComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public assessment: Assessment;
  @Input() public isAssessor: boolean;
  @Output() assessmentSubmittedEvent = new EventEmitter<object>();
  public isOverview = true;
  public currentQuestion: AssessmentQuestion;
  public questionQueue: QuestionQueue<AssessmentQuestion>;
  public categories: AssessmentCategory[];
  public currentCategory: AssessmentCategory;
  private subscriptions: Subscription[];

  public valid: boolean;

  public constructor(
    private questionValidator: QuestionValidator,
    private assessmentService: AssessmentService,
    private whitelabelService: WhitelabelService,
    private router: Router,
    private events: Events,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    super();
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.isOverview = false;
    this.setCurrentQuestion();
    this.setCategories();

    this.scrollToTop();

    this.subscriptions.push(
      this.events.subscribe(EVENT.NAVIGATE_BACKWARDS, () => this.moveBackwards()),
    );

    this.verifyAllAnswered();
  }

  public answerAllRandomly() {
    this.assessment.questions.forEach((q) => {
      if (!q.isAnswered && q.answerOptions?.length > 0) {
        const answerOption = q.answerOptions[this.getRandomInt(q.answerOptions.length)];
        q.answer.answerTemplateId = answerOption.id;
        q.answer.score = answerOption.score;

        this.assessmentService.submitAnswerAsync(this.assessment.id, q.answer);
      }
    });

    this.verifyAllAnswered();

    if (!this.isAssessor) {
      this.navigateToOverview();
    }
  }

  public answerAllCorrectly() {
    this.assessment.questions.forEach((q) => {
      if (!q.isAnswered && q.answerOptions?.length > 0) {
        const answerOption = q.answerOptions[0];
        q.answer.answerTemplateId = answerOption.id;
        q.answer.score = answerOption.score;
      }
    });

    this.verifyAllAnswered();

    if (!this.isAssessor) {
      this.navigateToOverview();
    }
  }

  public submitSingleQuestion(answer: AssessmentAnswer) {
    this.assessmentService.submitAnswerAsync(this.assessment.id, answer);

    if (this.isAssessor) {
      this.verifyAllAnswered();
      return;
    }

    this.nextQuestion();
  }

  public submitCurrentCategory() {
    this.currentQuestion = null;
    this.currentCategory = null;

    this.scrollToTop();
  }

  public previousQuestion() {
    if (!this.questionQueue.hasPrevious()) {
      return;
    }

    const question = this.questionQueue.reversePop();
    if (question.category.categoryName !== this.currentCategory.categoryName) {
      this.questionQueue.pop();
      this.currentCategory = null;
      return;
    }

    this.currentQuestion = question;
  }

  public nextQuestion() {
    if (!this.questionQueue.hasNext()) {
      this.currentQuestion = null;
      this.currentCategory = null;

      if (!this.isAssessor) {
        this.isOverview = true;
      }

      return;
    }

    const question = this.questionQueue.pop();
    if (this.isAssessor && question.category.categoryName !== this.currentCategory.categoryName) {
      this.questionQueue.reversePop();
      this.currentCategory = null;
      this.currentQuestion = null;

      return;
    }

    this.currentQuestion = question;
    this.scrollToTop();
  }

  public getAllQuestionsForCategory(category: AssessmentCategory): AssessmentQuestion[] {
    this.jumpToCategory(category);

    return this.assessment.questions.filter(
      (q) => q.parentQuestionId == null && q.category.id === category.id,
    );
  }

  public submit() {
    if (this.whitelabelService.isNHIS()) {
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: this.translate.instant('MESSAGES.CONFIRMATION.TITLE'),
            message: this.translate.instant('MESSAGES.CONFIRMATION.MESSAGE'),
          },
        })
        .afterClosed()
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe((res) => {
          if (res) {
            this.emitSubmitEvent();
          }
        });
    } else {
      this.emitSubmitEvent();
    }
  }

  emitSubmitEvent() {
    this.assessmentSubmittedEvent.emit({
      id: this.assessment.id,
      numberOfCategories: this.categories?.length,
      numberOfCategoriesFullyAnswered: this.categories?.filter((c) =>
        this.getCategoryFullyScored(c),
      )?.length,
    });
  }

  verifyAllAnswered() {
    this.valid = this.questionValidator.validateAllQuestionsAnswered(this.assessment);
  }

  verifyAllAnsweredInCategory(category: AssessmentCategory): boolean {
    return this.questionValidator.validateAllQuestionsAnsweredInCategory(this.assessment, category);
  }

  public getQuestionsForCategory(category: AssessmentCategory) {
    return this.assessment.questions.filter((q) => q.category.id === category.id);
  }

  public getCurrentQuestionNumberForCategory(category: AssessmentCategory): number {
    return this.categories.findIndex((c) => c === category) + 1;
  }

  public getAmountAnsweredForCategory(category: AssessmentCategory) {
    return this.getQuestionsForCategory(category).filter((q) => q.isAnswered).length;
  }

  public getAmountOfQuestionsForCategory(category: AssessmentCategory) {
    return this.getQuestionsForCategory(category).filter((q) => q.parentQuestionId == null).length;
  }

  public getAmountOfQuestionsForCategoryManyata(category: AssessmentCategory) {
    return this.getQuestionsForCategory(category).filter((q) => q.parentQuestionId != null).length;
  }

  public getAmountAnsweredCorrectlyForCategory(category: AssessmentCategory) {
    return this.getQuestionsForCategory(category).filter(
      (q) => q.parentQuestionId != null && q.isAnswered && q.answer.isAnsweredCorrectly,
    ).length;
  }

  public getCategoryFullyScored(category: AssessmentCategory): boolean {
    return (
      this.getAmountAnsweredCorrectlyForCategory(category) ===
      this.getAmountOfQuestionsForCategoryManyata(category)
    );
  }

  public getNHISScoreDefinition(category: AssessmentCategory): string {
    const totalPercentage = this.getTotalScorePercentage(category);

    if (totalPercentage < 40) return 'NC';
    if (totalPercentage > 40 && totalPercentage < 99) return 'PC';
    if (totalPercentage === 100) return 'FC';

    return 'NA';
  }

  public getTotalScorePercentage(category: AssessmentCategory): number {
    const totalQuestions = this.getQuestionsForCategory(category).filter(
      (q) => q.parentQuestionId != null,
    ).length;
    const correctAnswers = this.getQuestionsForCategory(category).filter(
      (q) => q.parentQuestionId != null && q.isAnswered && q.answer.isAnsweredCorrectly,
    ).length;

    const totalPercentage = (correctAnswers / totalQuestions) * 100;

    return Math.floor(totalPercentage);
  }

  public getScoreDefinition(category: AssessmentCategory): string {
    if (this.showNHISScore) {
      return `${this.getNHISScoreDefinition(category)} (${this.getTotalScorePercentage(category)})`;
    } else if (this.showManyataScore) {
      const answered = this.getAmountAnsweredCorrectlyForCategory(category);
      const total = this.getAmountOfQuestionsForCategoryManyata(category);
      const score = answered === total ? 1 : 0;
      return `${score} (${answered} / ${total})`;
    }
    return `${this.getAmountAnsweredForCategory(category)} / ${this.getAmountOfQuestionsForCategory(
      category,
    )}`;
  }

  setCurrentCategory(category: AssessmentCategory) {
    this.currentCategory = category;
    this.jumpToCategory(category);
    this.scrollToTop();
  }

  navigateToOverview(force = false) {
    if (!this.isAssessor || force) {
      this.isOverview = true;
    } else {
      this.goToCategoryOverview();
    }
  }

  goToCategoryOverview() {
    this.questionQueue.reversePop();
    this.currentCategory = null;
    this.currentQuestion = null;
  }

  isCurrentQuestionAnswered(): boolean {
    return (
      this.currentQuestion.isAnswered &&
      (this.currentQuestion.childQuestions == null ||
        this.currentQuestion.childQuestions.every((cq) => cq.isAnswered))
    );
  }

  ngOnDestroy(): void {
    while (this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  get showManyataScore(): boolean {
    return this.whitelabelService.isManyata() && this.isAssessor;
  }

  get showNHISScore(): boolean {
    return this.whitelabelService.isNHIS() && this.isAssessor;
  }

  get showTestButton(): boolean {
    return environment.showAssessmentTestButton && !this.whitelabelService.isKakamega();
  }

  private moveBackwards() {
    if (this.isAssessor && this.currentCategory) {
      this.navigateToOverview();
      return;
    } else if (!this.isAssessor) {
      this.previousQuestion();
      return;
    }

    this.router.navigateByUrl(this.isAssessor ? '/assessor/home' : '/home');
  }

  private getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  private setCurrentQuestion() {
    if (this.questionQueue == null) {
      this.assessment.questions.forEach((q) => {
        q.childQuestions = this.assessment.questions.filter((cq) => cq.parentQuestionId === q.id);
      });

      this.questionQueue = new QuestionQueue<AssessmentQuestion>(
        this.assessment.questions.filter((q) => q.parentQuestionId == null),
      );
    }

    while (this.questionQueue.hasNext()) {
      const q = this.questionQueue.pop();
      if (q.isAnswered) continue;

      this.questionQueue.reversePop();
      break;
    }

    this.nextQuestion();
  }

  private setCategories() {
    if (this.whitelabelService.isNHIS()) {
      this.categories = this.assessment.categories;
    } else {
      this.categories = this.assessment.questions
        .map((q: AssessmentQuestion) => q.category)
        .filter(
          (item: AssessmentCategory, index: number, list: AssessmentCategory[]) =>
            list.findIndex((_item) => item.id === _item.id) === index,
        );
    }
  }

  private jumpToCategory(category: AssessmentCategory) {
    this.questionQueue.reset();

    while (this.questionQueue.hasNext()) {
      const question = this.questionQueue.pop();

      if (question.category.id !== category.id) continue;

      this.currentQuestion = question;
      break;
    }
  }

  private scrollToTop() {
    setTimeout(() => {
      document.querySelector('.page-content').scrollTop = 0;
    }, 20);
  }
}
